export const deleteAuthCookies = () => {
  const value = document.cookie;
  const oneDayCookie = document.cookie
    .split('; ')
    .find((cookie) => cookie.split('=')[0] === '24-hour-cookie');
  const cookies = value.split('; ').filter((cookie) => {
    const cookieKey = cookie.split('=')[0];
    return (
      cookieKey === 'idToken'
      || cookieKey === 'accessToken'
      || cookieKey === 'FA_UT'
    );
  });
  if (process.env.REACT_APP_ENVIRONMENT !== 'production') {
    document.cookie = `${oneDayCookie}; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=.app.fintelconnect.com; path=/;`;
    cookies.forEach((cookie) => {
      document.cookie = `${cookie}; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=.app.fintelconnect.com; path=/;`;
    });
  }
};

import jwt, { JwtPayload } from 'jsonwebtoken';

export const getTypeFromCookie = () => {
  const secret: any = process.env.REACT_APP_COOKIE_SECRET_KEY;
  const userTypeCookie = document.cookie
    .split(';')
    .find((cookie: any) => cookie.trim().split('=')[0] === 'FA_UT')
    ?.trim()
    .split('=')[1];
  if (userTypeCookie) {
    const verifiedToken = jwt.verify(userTypeCookie, secret) as JwtPayload;
    return verifiedToken.data || null;
  }
  return null;
};

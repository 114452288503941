import styled from 'styled-components';
import flexGen from 'utils/flexGen';
import { colors, fonts } from 'styles/theme';
import toRem from 'utils/toRem';

interface StyledSideMenuHelpWrapperProps {
  hideSideMenu: boolean;
}

export const StyledSideMenuHelpWrapper = styled.div<StyledSideMenuHelpWrapperProps>`
  background-color: ${colors.color1};
  margin-bottom: ${toRem(32)};
  border-radius: ${(props) => (props.hideSideMenu ? '50%' : '100px')};
  font-family: ${fonts.font1};

  svg {
    fill: ${colors.color2};
  }

  a {
    ${flexGen('center', 'center')}
    width: ${(props) => (props.hideSideMenu ? '48px' : '112px')};
    height: 48px;
    text-decoration: none;
    color: ${colors.color2};
  }

  :hover {
    background-color: ${colors.color9};
  }
`;

export const StyledSidemenuKBLink = styled.a`
  :hover,
  :active,
  :focus {
    outline-style: none;
  }
`;

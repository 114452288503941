import React from 'react';
import Icon from 'components/Icon';
import { StyledLogo, StyledLogoTitle, StyledLogoSubtitle } from './styles';

interface LogoProps {
  hideSideMenu: boolean;
}

const Logo: React.FC<LogoProps> = ({ hideSideMenu }) => (
  <StyledLogo>
    <Icon icon="logo" width={25} height={32} viewbox="0 0 25 32" />

    {!hideSideMenu && <StyledLogoTitle>FINTEL</StyledLogoTitle>}
    {!hideSideMenu && <StyledLogoSubtitle>CONNECT</StyledLogoSubtitle>}
  </StyledLogo>
);

export default Logo;

import * as React from 'react';

const ActionType: any = {
  GET_ME: 'GET_ME',
};

interface IReducer {
  type: string;
  payload: {
    _id: string;
    userType: string;
    companyName: string;
    companyId: string;
    firstName: string;
    lastName: string;
    status: string;
  };
}

interface IMe {
  me: {
    _id: string;
    userType: string;
    companyName: string;
    companyId: string;
    firstName: string;
    lastName: string;
    status: string;
  };
}

const reducer: React.Reducer<IMe, IReducer> = (state, action) => {
  switch (action.type) {
    case ActionType.GET_ME:
      return { ...state, me: action.payload };
    default:
      return state;
  }
};

export default reducer;

import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { colors } from 'styles/theme';
import flexGen from 'utils/flexGen';
import hexToRGB from 'utils/hexToRGB';
import toRem from 'utils/toRem';

interface IStyledToastWrapper {
  type: string;
  isToastOpen: boolean;
}

export const StyledToastWrapper = styled.div<IStyledToastWrapper>`
  ${flexGen('space-between', 'center', 'row')}
  position: fixed;
  left: 25%;
  width: 50%;
  z-index: 100;

  height: ${toRem(40)};
  border-radius: ${toRem(4)};
  padding: ${toRem(8)} ${toRem(16)} ${toRem(8)} ${toRem(16)};
  background: ${(props) => (props.type === 'error'
    ? `rgba(${hexToRGB('#F28141')})`
    : `rgba(${hexToRGB(colors.color8)})`)};
  transition: bottom 0.5s;
  ${(props) => (props.isToastOpen ? 'bottom: 5%;' : 'bottom: -10%;')};
  /* @keyframes moveUp {
    from {
      bottom: -10%;
    }
    to {
      bottom: 5%;
    }
  }

  @keyframes moveDown {
    from {
      bottom: 5%;
    }
    to {
      bottom: -10%;
    }
  } */
`;

export const StyledToastMainTitle = styled.div`
  margin-right: ${toRem(8)};
  margin-left: ${toRem(8)};
`;

export const StyledToastRedirectLink = styled(Link)`
  color: ${colors.color1};
`;

export const StyledToastLeft = styled.section`
  ${flexGen('center', 'center')}
`;

const iconList: any = {
  logo: '<path fill-rule="evenodd" clip-rule="evenodd" d="M4.82289 23.7289V28.8051L0.484375 26.267V23.9851V18.9085V17.5113V8.75549L7.96724 4.37802L15.4506 0V5.07653L26.0781 11.2939V23.7289L15.4506 29.9462L11.1119 32.4844L7.96724 30.6448L6.77307 29.9462L11.1119 27.4081L15.4506 24.8697L21.7393 21.1905V13.832L15.4506 10.1531V15.2296L13.5002 16.3704L9.1614 18.9085L4.82289 21.4469V23.7289ZM9.16113 13.832L15.4504 10.1531V5.07653L4.82261 11.2939V16.3704L9.16113 13.832Z" fill="#F78D2D"/>',

  inbox:
    '<path fill-rule="evenodd" clip-rule="evenodd" d="M15.8327 1.6665C17.2134 1.6665 18.3327 2.78579 18.3327 4.1665V15.8332C18.3327 17.2139 17.2134 18.3332 15.8327 18.3332H4.16602C2.7853 18.3332 1.66602 17.2139 1.66602 15.8332V4.1665C1.66602 2.78579 2.7853 1.6665 4.16602 1.6665H15.8327ZM3.33268 15.8332C3.33268 16.2934 3.70578 16.6665 4.16602 16.6665H15.8327C16.2929 16.6665 16.666 16.2934 16.666 15.8332V11.6665H14.0827C13.7087 13.5089 12.119 14.9099 10.1873 14.9957L9.99935 14.9998C8.04654 14.9998 6.40751 13.6564 5.95599 11.8434L5.91603 11.6665H3.33268V15.8332ZM15.8327 3.33317H4.16602C3.70578 3.33317 3.33268 3.70627 3.33268 4.1665V9.99984H6.66602C7.09338 9.99984 7.4456 10.3215 7.49374 10.736L7.49935 10.8332C7.49935 12.2139 8.61864 13.3332 9.99935 13.3332C11.3307 13.3332 12.4191 12.2924 12.4951 10.9801L12.505 10.736C12.5494 10.3534 12.8529 10.0499 13.2355 10.0054L13.3327 9.99984H16.666V4.1665C16.666 3.70627 16.2929 3.33317 15.8327 3.33317Z"/>',

  sent: '<path fill-rule="evenodd" clip-rule="evenodd" d="M2.54697 8.35978C1.81768 8.11668 1.81768 7.08512 2.54697 6.84202L16.9457 2.04243C17.5711 1.83398 18.166 2.42892 17.9576 3.05427L13.158 17.453C12.9149 18.1823 11.8833 18.1823 11.6402 17.453L9.36691 10.6331L2.54697 8.35978ZM6.59488 7.6009L10.948 9.05195L12.3991 13.4051L15.3012 4.69879L6.59488 7.6009Z"/>',

  globe:
    '<path fill-rule="evenodd" clip-rule="evenodd" d="M9.99935 1.6665C14.6017 1.6665 18.3327 5.39746 18.3327 9.99984C18.3327 14.6022 14.6017 18.3332 9.99935 18.3332C5.39698 18.3332 1.66602 14.6022 1.66602 9.99984C1.66602 5.39746 5.39698 1.6665 9.99935 1.6665ZM12.4764 10.8338H7.52235C7.6148 12.5034 7.982 14.0234 8.54382 15.147C9.06706 16.1935 9.62619 16.6665 9.99938 16.6665C10.3726 16.6665 10.9317 16.1935 11.4549 15.147C12.0168 14.0234 12.384 12.5034 12.4764 10.8338ZM16.6143 10.834L14.1454 10.8342C14.0446 12.8625 13.5801 14.6767 12.8843 16.0125C14.8828 15.0512 16.3287 13.1228 16.6143 10.834ZM5.85331 10.8342L3.38436 10.834C3.67006 13.1227 5.11595 15.0511 7.11489 16.0119C6.46214 14.7601 6.0131 13.0874 5.87644 11.2119L5.85331 10.8342ZM7.11437 3.98714L7.09257 3.99855C5.10499 4.96303 3.66869 6.88583 3.38426 9.16636L5.85325 9.16629C5.95398 7.1377 6.41845 5.32312 7.11437 3.98714ZM9.99938 3.33317L9.93163 3.3381C9.56052 3.3908 9.03627 3.86771 8.54381 4.85264C7.98189 5.97646 7.61467 7.49678 7.52229 9.16669H12.4765C12.3841 7.49678 12.0169 5.97646 11.4549 4.85264C10.9317 3.80615 10.3726 3.33317 9.99938 3.33317ZM12.8838 3.98778L12.9026 4.02254C13.5884 5.35522 14.0457 7.1559 14.1454 9.16687L16.6144 9.16652C16.329 6.8774 14.883 4.94869 12.8838 3.98778Z"/>',

  archive:
    '<path fill-rule="evenodd" clip-rule="evenodd" d="M15.8337 1.66699C17.2144 1.66699 18.3337 2.78628 18.3337 4.16699V15.8337C18.3337 17.2144 17.2144 18.3337 15.8337 18.3337H4.16699C2.78628 18.3337 1.66699 17.2144 1.66699 15.8337V4.16699C1.66699 2.78628 2.78628 1.66699 4.16699 1.66699H15.8337ZM16.667 6.66699H3.33366V15.8337C3.33366 16.2939 3.70675 16.667 4.16699 16.667H15.8337C16.2939 16.667 16.667 16.2939 16.667 15.8337V6.66699ZM10.0976 14.9947L10.0004 15.0003L9.93033 14.997L9.83314 14.9835L9.74029 14.9589L9.64772 14.9223L9.56648 14.8787L9.50442 14.8367L9.41114 14.7562L6.91108 12.2562C6.58563 11.9308 6.58563 11.4032 6.91108 11.0777C7.21149 10.7773 7.68419 10.7542 8.01111 11.0084L8.08962 11.0777L9.16699 12.1545L9.16706 9.16699C9.16706 8.73963 9.48876 8.3874 9.90321 8.33926L10.0004 8.33366C10.4607 8.33366 10.8338 8.70675 10.8338 9.16699L10.8337 12.1545L11.9112 11.0777C12.2116 10.7773 12.6843 10.7542 13.0112 11.0084L13.0897 11.0777C13.3902 11.3781 13.4133 11.8508 13.1591 12.1777L13.0897 12.2562L10.5897 14.7562L10.5184 14.8198L10.4602 14.8621L10.3786 14.9098L10.2838 14.9509L10.2221 14.9705L10.0976 14.9947ZM15.8337 3.33366H4.16699C3.70675 3.33366 3.33366 3.70675 3.33366 4.16699V5.00033H16.667V4.16699C16.667 3.70675 16.2939 3.33366 15.8337 3.33366Z"/>',

  template:
    '<path fill-rule="evenodd" clip-rule="evenodd" d="M11.6673 1.6665C11.8883 1.6665 12.1003 1.7543 12.2566 1.91058L16.4232 6.07725C16.5795 6.23353 16.6673 6.44549 16.6673 6.6665V7.49984C16.6673 7.96007 16.2942 8.33317 15.834 8.33317H10.834C10.4066 8.33317 10.0544 8.01147 10.0063 7.59702L10.0007 7.49984L9.99982 3.33317H5.83398C5.37375 3.33317 5.00065 3.70627 5.00065 4.1665V15.8332C5.00065 16.2934 5.37375 16.6665 5.83398 16.6665H7.50065C7.96089 16.6665 8.33398 17.0396 8.33398 17.4998C8.33398 17.9601 7.96089 18.3332 7.50065 18.3332H5.83398C4.45327 18.3332 3.33398 17.2139 3.33398 15.8332V4.1665C3.33398 2.78579 4.45327 1.6665 5.83398 1.6665H11.6673ZM14.4114 10.2439C14.7118 9.94351 15.1845 9.9204 15.5114 10.1746L15.5899 10.2439L18.0899 12.7439C18.3903 13.0443 18.4134 13.517 18.1592 13.8439L18.0899 13.9224L13.9232 18.0891C13.793 18.2193 13.6241 18.302 13.4435 18.3259L13.334 18.3332H10.834C10.4066 18.3332 10.0544 18.0115 10.0063 17.597L10.0007 17.4998V14.9998C10.0007 14.8157 10.0616 14.6378 10.1724 14.4931L10.2447 14.4106L14.4114 10.2439ZM15.0007 12.0117L11.6673 15.345V16.6665H12.9888L16.3221 13.3332L15.0007 12.0117ZM11.6665 3.67817L11.6673 6.6665H14.6556L11.6665 3.67817Z"/>',

  list: '<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 16 12" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M1 10C1.55228 10 2 10.4477 2 11C2 11.5523 1.55228 12 1 12C0.447715 12 0 11.5523 0 11C0 10.4477 0.447715 10 1 10ZM15 10C15.5523 10 16 10.4477 16 11C16 11.5523 15.5523 12 15 12H5C4.44772 12 4 11.5523 4 11C4 10.4477 4.44772 10 5 10H15ZM1 5C1.55228 5 2 5.44772 2 6C2 6.55228 1.55228 7 1 7C0.447715 7 0 6.55228 0 6C0 5.44772 0.447715 5 1 5ZM15 5C15.5523 5 16 5.44772 16 6C16 6.55228 15.5523 7 15 7H5C4.44772 7 4 6.55228 4 6C4 5.44772 4.44772 5 5 5H15ZM1 0C1.55228 0 2 0.447715 2 1C2 1.55228 1.55228 2 1 2C0.447715 2 0 1.55228 0 1C0 0.447715 0.447715 0 1 0ZM15 0C15.5523 0 16 0.447715 16 1C16 1.55228 15.5523 2 15 2H5C4.44772 2 4 1.55228 4 1C4 0.447715 4.44772 0 5 0H15Z"/></svg>',

  help: '<path fill-rule="evenodd" clip-rule="evenodd" d="M10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0ZM10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2ZM10 14C10.5523 14 11 14.4477 11 15C11 15.5523 10.5523 16 10 16C9.44771 16 9 15.5523 9 15C9 14.4477 9.44771 14 10 14ZM10 4C12.2091 4 14 5.79086 14 8C14 9.79481 12.8179 11.3135 11.1898 11.8201L11 11.874V12C11 12.5523 10.5523 13 10 13C9.48716 13 9.06449 12.614 9.00673 12.1166L9 12V11C9 10.4872 9.38604 10.0645 9.88338 10.0067L10.1493 9.99451C11.1841 9.91817 12 9.05436 12 8C12 6.89543 11.1046 6 10 6C8.89543 6 8 6.89543 8 8C8 8.55229 7.55228 9 7 9C6.44772 9 6 8.55229 6 8C6 5.79086 7.79086 4 10 4Z" />',

  reload:
    '<path fill-rule="evenodd" clip-rule="evenodd" d="M8.29289 4.29289L10.2929 2.29289C10.6834 1.90237 11.3166 1.90237 11.7071 2.29289C12.0676 2.65338 12.0953 3.22061 11.7903 3.6129L11.7071 3.70711L11.295 4.11955C14.5423 4.72694 17 7.57646 17 11C17 14.866 13.866 18 10 18C6.13401 18 3 14.866 3 11C3 9.94933 3.23232 8.92963 3.67401 7.99975C3.91097 7.50088 4.50748 7.28856 5.00634 7.52552C5.50521 7.76248 5.71753 8.35899 5.48057 8.85785C5.16558 9.521 5 10.2478 5 11C5 13.7614 7.23858 16 10 16C12.7614 16 15 13.7614 15 11C15 8.84177 13.6326 7.00291 11.7168 6.30255C12.0976 6.69468 12.0942 7.32006 11.7071 7.70711C11.3466 8.06759 10.7794 8.09532 10.3871 7.7903L10.2929 7.70711L8.29289 5.70711C7.93241 5.34662 7.90468 4.77939 8.2097 4.3871L8.29289 4.29289L10.2929 2.29289L8.29289 4.29289Z" fill="#3B3B3B"/>',

  search:
    '<path fill-rule="evenodd" clip-rule="evenodd" d="M9 2C12.866 2 16 5.13401 16 9C16 10.5764 15.4789 12.0311 14.5996 13.2012L14.6545 13.2441L14.7071 13.2929L17.7071 16.2929C18.0976 16.6834 18.0976 17.3166 17.7071 17.7071C17.3466 18.0676 16.7794 18.0953 16.3871 17.7903L16.2929 17.7071L13.2929 14.7071C13.2587 14.6729 13.2275 14.6368 13.1993 14.5992C12.031 15.4789 10.5764 16 9 16C5.13401 16 2 12.866 2 9C2 5.13401 5.13401 2 9 2ZM9 4C6.23858 4 4 6.23858 4 9C4 11.7614 6.23858 14 9 14C11.7614 14 14 11.7614 14 9C14 6.23858 11.7614 4 9 4Z" fill="#ffffff"/>',

  hide: '<path fill-rule="evenodd" clip-rule="evenodd" d="M4 17C4 17.5523 3.55228 18 3 18C2.44772 18 2 17.5523 2 17V3C2 2.44772 2.44772 2 3 2C3.55228 2 4 2.44772 4 3V17ZM6 10L6.004 9.918L6.02024 9.79927L6.04974 9.68786L6.09367 9.57678L6.146 9.47929L6.19631 9.40484L6.29289 9.29289L9.29289 6.29289C9.68342 5.90237 10.3166 5.90237 10.7071 6.29289C11.0676 6.65338 11.0953 7.22061 10.7903 7.6129L10.7071 7.70711L9.415 9H17C17.5523 9 18 9.44772 18 10C18 10.5523 17.5523 11 17 11H9.415L10.7071 12.2929C11.0676 12.6534 11.0953 13.2206 10.7903 13.6129L10.7071 13.7071C10.3466 14.0676 9.77939 14.0953 9.3871 13.7903L9.29289 13.7071L6.29289 10.7071L6.2515 10.6631L6.19633 10.5953L6.12467 10.4841L6.09365 10.4231L6.05989 10.3417L6.03585 10.266L6.00683 10.1175L6 10Z" fill="#3B3B3B"/>',

  open: '<path fill-rule="evenodd" clip-rule="evenodd" d="M16 3C16 2.44772 16.4477 2 17 2C17.5523 2 18 2.44772 18 3V17C18 17.5523 17.5523 18 17 18C16.4477 18 16 17.5523 16 17V3ZM14 10L13.996 10.082L13.9798 10.2007L13.9503 10.3121L13.9063 10.4232L13.854 10.5207L13.8037 10.5952L13.7071 10.7071L10.7071 13.7071C10.3166 14.0976 9.68342 14.0976 9.29289 13.7071C8.93241 13.3466 8.90468 12.7794 9.2097 12.3871L9.29289 12.2929L10.585 11H3C2.44772 11 2 10.5523 2 10C2 9.44772 2.44772 9 3 9H10.585L9.29289 7.70711C8.93241 7.34662 8.90468 6.77939 9.2097 6.3871L9.29289 6.29289C9.65338 5.93241 10.2206 5.90468 10.6129 6.2097L10.7071 6.29289L13.7071 9.29289L13.7485 9.33685L13.8037 9.40469L13.8753 9.51594L13.9063 9.5769L13.9401 9.65835L13.9642 9.73401L13.9932 9.88253L14 10Z" fill="#3B3B3B"/>',

  bell: '<path d="M13.8613 11.5098C13.3047 10.8945 12.2207 9.98633 12.2207 6.96875C12.2207 4.71289 10.6387 2.89648 8.4707 2.42773V1.8125C8.4707 1.31445 8.06055 0.875 7.5625 0.875C7.03516 0.875 6.625 1.31445 6.625 1.8125V2.42773C4.45703 2.89648 2.875 4.71289 2.875 6.96875C2.875 9.98633 1.79102 10.8945 1.23438 11.5098C1.05859 11.6855 0.970703 11.9199 1 12.125C1 12.623 1.35156 13.0625 1.9375 13.0625H13.1582C13.7441 13.0625 14.0957 12.623 14.125 12.125C14.125 11.9199 14.0371 11.6855 13.8613 11.5098ZM2.96289 11.6562C3.57812 10.8652 4.25195 9.48828 4.28125 6.99805C4.28125 6.99805 4.28125 6.99805 4.28125 6.96875C4.28125 5.18164 5.74609 3.6875 7.5625 3.6875C9.34961 3.6875 10.8438 5.18164 10.8438 6.96875C10.8438 6.99805 10.8145 6.99805 10.8145 6.99805C10.8438 9.48828 11.5176 10.8652 12.1328 11.6562H2.96289ZM7.5625 15.875C8.58789 15.875 9.4082 15.0547 9.4082 14H5.6875C5.6875 15.0547 6.50781 15.875 7.5625 15.875Z" />',

  file: '<path fill-rule="evenodd" clip-rule="evenodd" d="M11 2C11.2652 2 11.5196 2.10536 11.7071 2.29289L15.7071 6.29289C15.8946 6.48043 16 6.73478 16 7V15C16 16.6569 14.6569 18 13 18H7C5.34315 18 4 16.6569 4 15V5C4 3.34315 5.34315 2 7 2H11ZM9.999 4H7C6.44772 4 6 4.44772 6 5V15C6 15.5523 6.44772 16 7 16H13C13.5523 16 14 15.5523 14 15V8H11C10.4872 8 10.0645 7.61396 10.0067 7.11662L10 7L9.999 4Z" />',

  merchant:
    '<path fill-rule="evenodd" clip-rule="evenodd" d="M9.16699 1.66675H10.8337C12.1651 1.66675 13.2534 2.70751 13.3294 4.01985L13.3337 4.16675V5.00008H15.8337C17.2144 5.00008 18.3337 6.11937 18.3337 7.50008V15.8334C18.3337 17.2141 17.2144 18.3334 15.8337 18.3334H4.16699C2.78628 18.3334 1.66699 17.2141 1.66699 15.8334V7.50008C1.66699 6.11937 2.78628 5.00008 4.16699 5.00008H6.66699V4.16675C6.66699 2.83535 7.70776 1.74703 9.0201 1.67099L9.16699 1.66675H10.8337H9.16699ZM16.667 13.3334H3.33366V15.8334C3.33366 16.2937 3.70675 16.6667 4.16699 16.6667H15.8337C16.2939 16.6667 16.667 16.2937 16.667 15.8334V13.3334ZM15.8337 6.66675H4.16699C3.70675 6.66675 3.33366 7.03984 3.33366 7.50008V11.6667H16.667V7.50008C16.667 7.03984 16.2939 6.66675 15.8337 6.66675ZM10.8337 3.33341H9.16699C8.73963 3.33341 8.3874 3.65511 8.33926 4.06956L8.33366 4.16675V5.00008H11.667V4.16675C11.667 3.73938 11.3453 3.38716 10.9308 3.33902L10.8337 3.33341Z"/>',

  text: '<path fill-rule="evenodd" clip-rule="evenodd" d="M18.1544 9C18.7442 9 19.2223 9.44772 19.2223 10C19.2223 10.5523 18.7442 11 18.1544 11H16.0186V15C16.0186 15.5523 15.5404 16 14.9507 16C14.3609 16 13.8827 15.5523 13.8827 15V11H11.7469C11.1571 11 10.679 10.5523 10.679 10C10.679 9.44772 11.1571 9 11.7469 9H18.1544ZM13.8827 4C14.4725 4 14.9507 4.44772 14.9507 5C14.9507 5.55228 14.4725 6 13.8827 6H9.61111V15C9.61111 15.5523 9.13299 16 8.5432 16C7.95341 16 7.47529 15.5523 7.47529 15V6H3.20365C2.61386 6 2.13574 5.55228 2.13574 5C2.13574 4.44772 2.61386 4 3.20365 4H13.8827Z"/>',

  bubble:
    '<path fill-rule="evenodd" clip-rule="evenodd" d="M8.35078 13.9985L3.6247 17.779C2.96993 18.3028 2 17.8366 2 16.9982V4.99972C2 3.34302 3.34315 2 5 2H15C16.6569 2 18 3.34302 18 4.99972V10.9988C18 12.6555 16.6569 13.9985 15 13.9985H8.35078ZM4 14.9178L7.37531 12.2178C7.55262 12.076 7.77293 11.9987 8 11.9987H15C15.5523 11.9987 16 11.551 16 10.9988V4.99972C16 4.44749 15.5523 3.99981 15 3.99981H5C4.44772 3.99981 4 4.44749 4 4.99972V14.9178Z"/>',

  plus: '<path fill-rule="evenodd" clip-rule="evenodd" d="M10 2C9.44772 2 9 2.44772 9 3L9 9L3 9C2.44772 9 2 9.44772 2 10C2 10.5523 2.44772 11 3 11L9 11L9 17C9 17.5523 9.44772 18 10 18C10.5523 18 11 17.5523 11 17L11 11L17 11C17.5523 11 18 10.5523 18 10C18 9.44772 17.5523 9 17 9L11 9L11 3C11 2.44772 10.5523 2 10 2Z" fill="white"/>',

  profile:
    '<path fill-rule="evenodd" clip-rule="evenodd" d="M7 12H13C15.7614 12 18 14.2386 18 17C18 17.5523 17.5523 18 17 18C16.4872 18 16.0645 17.614 16.0067 17.1166L15.9949 16.8237C15.907 15.3072 14.6928 14.093 13.1763 14.0051L13 14H7C5.34315 14 4 15.3431 4 17C4 17.5523 3.55228 18 3 18C2.44772 18 2 17.5523 2 17C2 14.3112 4.12231 12.1182 6.78311 12.0046L7 12H13H7ZM10 2C12.7614 2 15 4.23858 15 7C15 9.76142 12.7614 12 10 12C7.23858 12 5 9.76142 5 7C5 4.23858 7.23858 2 10 2ZM10 4C8.34315 4 7 5.34315 7 7C7 8.65685 8.34315 10 10 10C11.6569 10 13 8.65685 13 7C13 5.34315 11.6569 4 10 4Z"/>',

  chev_down:
    '<path fill-rule="evenodd" clip-rule="evenodd" d="M16.7072 7.70725L10.7073 13.7073C10.3163 14.0983 9.68425 14.0983 9.29325 13.7073L3.29325 7.70725C2.90225 7.31625 2.90225 6.68425 3.29325 6.29325C3.68425 5.90225 4.31625 5.90225 4.70725 6.29325L10.0002 11.5862L15.2933 6.29325C15.6842 5.90225 16.3162 5.90225 16.7072 6.29325C16.9022 6.48825 17.0002 6.74425 17.0002 7.00025C17.0002 7.25625 16.9022 7.51225 16.7072 7.70725Z"/>',

  chev_right:
    '<path fill-rule="evenodd" clip-rule="evenodd" d="M11.4818 9.99982L6.34175 15.2468C5.92575 15.6108 5.88375 16.2428 6.24775 16.6578C6.61175 17.0738 7.24375 17.1158 7.65875 16.7528L13.6587 10.7518C14.1138 10.3538 14.1138 9.64582 13.6587 9.24782L7.65875 3.24782C7.24375 2.88382 6.61175 2.92582 6.24775 3.34082C5.88375 3.75682 5.92575 4.38882 6.34175 4.75182L11.4818 9.99982Z"/>',

  chev_left:
    '<path fill-rule="evenodd" clip-rule="evenodd" d="M12.7073 14.2933C13.0982 14.6842 13.0982 15.3162 12.7073 15.7072C12.3163 16.0982 11.6842 16.0982 11.2933 15.7072L6.29325 10.7073C5.90225 10.3163 5.90225 9.68425 6.29325 9.29325L11.2933 4.29325C11.6842 3.90225 12.3163 3.90225 12.7073 4.29325C13.0982 4.68425 13.0982 5.31625 12.7073 5.70725L8.41425 10.0002L12.7073 14.2933Z"/>',

  close:
    '<path fill-rule="evenodd" clip-rule="evenodd" d="M7.4141 6L9.7071 3.707C10.0981 3.316 10.0981 2.684 9.7071 2.293C9.3161 1.902 8.6841 1.902 8.2931 2.293L6.0001 4.586L3.7071 2.293C3.3161 1.902 2.6841 1.902 2.2931 2.293C1.9021 2.684 1.9021 3.316 2.2931 3.707L4.5861 6L2.2931 8.293C1.9021 8.684 1.9021 9.316 2.2931 9.707C2.4881 9.902 2.7441 10 3.0001 10C3.2561 10 3.5121 9.902 3.7071 9.707L6.0001 7.414L8.2931 9.707C8.4881 9.902 8.7441 10 9.0001 10C9.2561 10 9.5121 9.902 9.7071 9.707C10.0981 9.316 10.0981 8.684 9.7071 8.293L7.4141 6Z"/>',

  sort: '<path fill-rule="evenodd" clip-rule="evenodd" d="M7.53609 1.93349C7.19283 1.66659 6.69651 1.69086 6.38108 2.00628L3.75608 4.63128L3.68948 4.70529C3.23955 5.26268 3.62915 6.125 4.3748 6.125H9.6248L9.72423 6.11976C10.4365 6.04378 10.7708 5.15853 10.2435 4.63128L7.61852 2.00628L7.53609 1.93349ZM10.2435 9.36872C10.7947 8.8175 10.4043 7.875 9.6248 7.875H4.3748C3.59526 7.875 3.20486 8.8175 3.75608 9.36872L6.38108 11.9937C6.72279 12.3354 7.27681 12.3354 7.61852 11.9937L10.2435 9.36872Z" fill="#022658"/>',

  close_lg:
    '<path fill-rule="evenodd" clip-rule="evenodd" d="M11.4142 10L15.7072 5.70701C16.0982 5.31601 16.0982 4.68401 15.7072 4.29301C15.3162 3.90201 14.6842 3.90201 14.2933 4.29301L10.0002 8.58601L5.70725 4.29301C5.31625 3.90201 4.68425 3.90201 4.29325 4.29301C3.90225 4.68401 3.90225 5.31601 4.29325 5.70701L8.58625 10L4.29325 14.293C3.90225 14.684 3.90225 15.316 4.29325 15.707C4.48825 15.902 4.74425 16 5.00025 16C5.25625 16 5.51225 15.902 5.70725 15.707L10.0002 11.414L14.2933 15.707C14.4882 15.902 14.7443 16 15.0002 16C15.2562 16 15.5122 15.902 15.7072 15.707C16.0982 15.316 16.0982 14.684 15.7072 14.293L11.4142 10Z" />',

  publisher:
    '<path fill-rule="evenodd" clip-rule="evenodd" d="M5.83366 13.3334H14.167C16.4682 13.3334 18.3337 15.1988 18.3337 17.5C18.3337 17.9603 17.9606 18.3334 17.5003 18.3334C17.073 18.3334 16.7207 18.0117 16.6726 17.5972L16.6627 17.3531C16.5895 16.0894 15.5776 15.0775 14.3139 15.0043L14.167 15H5.83366C4.45295 15 3.33366 16.1193 3.33366 17.5C3.33366 17.9603 2.96056 18.3334 2.50033 18.3334C2.04009 18.3334 1.66699 17.9603 1.66699 17.5C1.66699 15.2594 3.43558 13.4318 5.65292 13.3372L5.83366 13.3334H14.167H5.83366ZM10.0003 1.66669C12.7617 1.66669 15.0003 3.90526 15.0003 6.66669C15.0003 9.42811 12.7617 11.6667 10.0003 11.6667C7.2389 11.6667 5.00033 9.42811 5.00033 6.66669C5.00033 3.90526 7.2389 1.66669 10.0003 1.66669ZM10.0003 3.33335C8.15938 3.33335 6.66699 4.82574 6.66699 6.66669C6.66699 8.50764 8.15938 10 10.0003 10C11.8413 10 13.3337 8.50764 13.3337 6.66669C13.3337 4.82574 11.8413 3.33335 10.0003 3.33335Z"/>',

  tag: '<path fill-rule="evenodd" clip-rule="evenodd" d="M8.78594 1.66669C9.44334 1.66669 10.0738 1.92784 10.5387 2.39269L17.6077 9.46168C18.5757 10.4297 18.5757 11.9991 17.6077 12.9671L12.9674 17.6073C11.9994 18.5754 10.43 18.5754 9.46198 17.6073L2.393 10.5384C1.92814 10.0735 1.66699 9.44303 1.66699 8.78563V4.14542C1.66699 2.77645 2.77676 1.66669 4.14573 1.66669H8.78594ZM8.78594 3.33335H4.14573C3.69723 3.33335 3.33366 3.69693 3.33366 4.14542V8.78563C3.33366 9.001 3.41922 9.20756 3.57151 9.35985L10.6405 16.4288C10.9576 16.746 11.4718 16.746 11.7889 16.4288L16.4291 11.7886C16.7463 11.4715 16.7463 10.9573 16.4291 10.6402L9.36015 3.5712C9.20786 3.41891 9.00131 3.33335 8.78594 3.33335ZM6.66699 5.00002C7.58747 5.00002 8.33366 5.74621 8.33366 6.66669C8.33366 7.58716 7.58747 8.33335 6.66699 8.33335C5.74652 8.33335 5.00033 7.58716 5.00033 6.66669C5.00033 5.74621 5.74652 5.00002 6.66699 5.00002Z"/>',

  check:
    '<path fill-rule="evenodd" clip-rule="evenodd" d="M9.91007 17.4959L3.70711 11.2929C3.31658 10.9024 2.68342 10.9024 2.29289 11.2929C1.90237 11.6834 1.90237 12.3166 2.29289 12.7071L9.29289 19.7071C9.71682 20.131 10.4159 20.0893 10.7863 19.6178L21.7863 5.61783C22.1275 5.18356 22.0521 4.55491 21.6178 4.21369C21.1835 3.87248 20.5549 3.94792 20.2137 4.38219L9.91007 17.4959Z"/>',

  home: '<path fill-rule="evenodd" clip-rule="evenodd" d="M7.001 18C5.34448 18 4.0016 16.6569 4.0016 15V11H3.0018C2.11108 11 1.665 9.92286 2.29484 9.29289L9.29304 2.29289C9.68348 1.90237 10.3165 1.90237 10.707 2.29289L17.7052 9.29289C18.335 9.92286 17.8889 11 16.9982 11H15.9984V15C15.9984 16.6569 14.6555 18 12.999 18H7.001ZM10 4.41454L5.09242 9.32338L5.22529 9.41792C5.69757 9.78375 6.0016 10.3564 6.0016 11V15C6.0016 15.5524 6.44914 16 7.001 16L8 15.999V13C8 12.4477 8.44772 12 9 12H11C11.5523 12 12 12.4477 12 13V15.999L12.999 16C13.5509 16 13.9984 15.5524 13.9984 15V11C13.9984 10.2979 14.3602 9.68023 14.9076 9.32338L10 4.41454Z"/>',

  map: '<path fill-rule="evenodd" clip-rule="evenodd" d="M10 2C13.866 2 17 5.18858 17 9.12189C17 11.7112 14.9045 14.4482 10.8016 17.4281L10.445 17.6841L10 18L9.55495 17.6841C5.21699 14.6047 3 11.7852 3 9.12189C3 5.18858 6.13401 2 10 2ZM10 4C7.24638 4 5 6.28549 5 9.12189C5 10.7706 6.48662 12.8514 9.68512 15.297L10 15.5348L10.3149 15.297C13.5134 12.8514 15 10.7706 15 9.12189C15 6.28549 12.7536 4 10 4ZM10 7C11.1046 7 12 7.89543 12 9C12 10.1046 11.1046 11 10 11C8.89543 11 8 10.1046 8 9C8 7.89543 8.89543 7 10 7Z"/>',

  align_center:
    '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M3 3C2.44772 3 2 3.44772 2 4C2 4.55228 2.44772 5 3 5H17C17.5523 5 18 4.55228 18 4C18 3.44772 17.5523 3 17 3H3ZM5 15C4.44772 15 4 15.4477 4 16C4 16.5523 4.44772 17 5 17H15C15.5523 17 16 16.5523 16 16C16 15.4477 15.5523 15 15 15H5ZM2 12C2 11.4477 2.44772 11 3 11H17C17.5523 11 18 11.4477 18 12C18 12.5523 17.5523 13 17 13H3C2.44772 13 2 12.5523 2 12ZM5 7C4.44772 7 4 7.44772 4 8C4 8.55228 4.44772 9 5 9H15C15.5523 9 16 8.55228 16 8C16 7.44772 15.5523 7 15 7H5Z"/></svg>',
  align_justify:
    '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M17 15C17.5523 15 18 15.4477 18 16C18 16.5523 17.5523 17 17 17H3C2.44772 17 2 16.5523 2 16C2 15.4477 2.44772 15 3 15H17ZM17 11C17.5523 11 18 11.4477 18 12C18 12.5523 17.5523 13 17 13H3C2.44772 13 2 12.5523 2 12C2 11.4477 2.44772 11 3 11H17ZM17 7C17.5523 7 18 7.44772 18 8C18 8.55228 17.5523 9 17 9H3C2.44772 9 2 8.55228 2 8C2 7.44772 2.44772 7 3 7H17ZM17 3C17.5523 3 18 3.44772 18 4C18 4.55228 17.5523 5 17 5H3C2.44772 5 2 4.55228 2 4C2 3.44772 2.44772 3 3 3H17Z" /></svg>',
  align_left:
    '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M3 3C2.44772 3 2 3.44772 2 4C2 4.55228 2.44772 5 3 5H17C17.5523 5 18 4.55228 18 4C18 3.44772 17.5523 3 17 3H3ZM3 15C2.44772 15 2 15.4477 2 16C2 16.5523 2.44772 17 3 17H13C13.5523 17 14 16.5523 14 16C14 15.4477 13.5523 15 13 15H3ZM2 12C2 11.4477 2.44772 11 3 11H17C17.5523 11 18 11.4477 18 12C18 12.5523 17.5523 13 17 13H3C2.44772 13 2 12.5523 2 12ZM3 7C2.44772 7 2 7.44772 2 8C2 8.55228 2.44772 9 3 9H13C13.5523 9 14 8.55228 14 8C14 7.44772 13.5523 7 13 7H3Z"/></svg>',
  align_right:
    '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M3 3C2.44772 3 2 3.44772 2 4C2 4.55228 2.44772 5 3 5H17C17.5523 5 18 4.55228 18 4C18 3.44772 17.5523 3 17 3H3ZM7 15C6.44772 15 6 15.4477 6 16C6 16.5523 6.44771 17 7 17H17C17.5523 17 18 16.5523 18 16C18 15.4477 17.5523 15 17 15H7ZM2 12C2 11.4477 2.44772 11 3 11H17C17.5523 11 18 11.4477 18 12C18 12.5523 17.5523 13 17 13H3C2.44772 13 2 12.5523 2 12ZM7 7C6.44772 7 6 7.44772 6 8C6 8.55228 6.44771 9 7 9H17C17.5523 9 18 8.55228 18 8C18 7.44772 17.5523 7 17 7H7Z"/></svg>',

  bold: '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fill-rule="evenodd" clip-rule="evenodd" d="M5 18C4.44772 18 4 17.5523 4 17V3C4 2.44772 4.44772 2 5 2H10C12.2463 2 14 4.04599 14 6.5C14 7.50829 13.7039 8.44769 13.1993 9.20461C14.8407 9.77921 16 11.5047 16 13.5C16 15.8818 14.3479 17.8793 12.1969 17.9947L12 18H5ZM12.1327 11.0056L12 11H6V16H12C13.0674 16 14 14.912 14 13.5C14 12.1468 13.1435 11.0912 12.1327 11.0056ZM10 4H6V9H10C11.0674 9 12 7.91199 12 6.5C12 5.14685 11.1435 4.09123 10.1327 4.00561L10 4Z" /></svg>',

  color:
    '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M5.09252 14.9999C5.66489 14.9999 5.95717 14.7795 6.16421 14.1673L7.26023 11.0695H12.7039L13.8121 14.1673C14.0191 14.7795 14.3114 14.9999 14.8838 14.9999C15.4561 14.9999 15.8337 14.6571 15.8337 14.0938C15.8337 13.9102 15.7971 13.7388 15.7119 13.5061L11.4008 1.84952C11.1451 1.16386 10.6823 0.833252 9.98814 0.833252C9.29399 0.833252 8.84341 1.1516 8.59983 1.83728L4.27658 13.5183C4.20351 13.751 4.16699 13.9224 4.16699 14.1061C4.16699 14.6571 4.52016 14.9999 5.09252 14.9999ZM7.79606 9.40427L9.9516 3.25763H10.0125L12.168 9.40427H7.79606Z" /><rect x="3.3335" y="16.6665" width="13.3333" height="1.66667" rx="0.833334" /></svg>',
  background:
    '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M16 11.6C16 9.24359 14 6.04359 10 2C6 6.04359 4 9.24359 4 11.6C4 15.1346 6.68629 18 10 18L10.22 17.9958C13.4318 17.8723 16 15.0561 16 11.6ZM10 4.904V16C7.82074 16 6 14.0579 6 11.6C6 10.167 7.29785 7.88569 9.97057 4.93615L10 4.904Z" /></svg>',

  italic:
    '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M16 2C16.5523 2 17 2.44772 17 3C17 3.55228 16.5523 4 16 4H12.754L9.325 16H12C12.5523 16 13 16.4477 13 17C13 17.5523 12.5523 18 12 18H4C3.44772 18 3 17.5523 3 17C3 16.4477 3.44772 16 4 16H7.245L10.674 4H8C7.44772 4 7 3.55228 7 3C7 2.44772 7.44772 2 8 2H16Z" /></svg>',

  link: '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M3.47186 9.42136L4.31457 8.57864C4.70707 8.18615 5.34343 8.18615 5.73593 8.57864C6.10039 8.9431 6.12643 9.51784 5.81403 9.91234L5.73593 10L4.89322 10.8427C3.71573 12.0202 3.71573 13.9293 4.89322 15.1068C6.02866 16.2422 7.84437 16.2828 9.0284 15.2284L9.15729 15.1068L10 14.2641C10.3925 13.8716 11.0289 13.8716 11.4214 14.2641C11.7858 14.6285 11.8119 15.2033 11.4995 15.5978L11.4214 15.6854L10.5786 16.5281C8.61616 18.4906 5.43435 18.4906 3.47186 16.5281C1.56102 14.6173 1.51074 11.5505 3.32101 9.57878L3.47186 9.42136ZM9.42136 3.47186C11.3838 1.50938 14.5657 1.50938 16.5281 3.47186C18.4906 5.43435 18.4906 8.61616 16.5281 10.5786L15.6854 11.4214C15.2929 11.8139 14.6566 11.8139 14.2641 11.4214C13.8716 11.0289 13.8716 10.3925 14.2641 10L15.1068 9.15729C16.2843 7.9798 16.2843 6.07071 15.1068 4.89322C13.9293 3.71573 12.0202 3.71573 10.8427 4.89322L10 5.73593C9.6075 6.12843 8.97114 6.12843 8.57864 5.73593C8.18615 5.34343 8.18615 4.70707 8.57864 4.31457L9.42136 3.47186ZM10.4214 8.15729C10.8139 7.76479 11.4502 7.76479 11.8427 8.15729C12.2352 8.54978 12.2352 9.18615 11.8427 9.57864L9.57864 11.8427C9.18615 12.2352 8.54978 12.2352 8.15729 11.8427C7.76479 11.4502 7.76479 10.8139 8.15729 10.4214L10.4214 8.15729Z"/></svg>',

  list_bullet:
    '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M3 14C3.55228 14 4 14.4477 4 15C4 15.5523 3.55228 16 3 16C2.44772 16 2 15.5523 2 15C2 14.4477 2.44772 14 3 14ZM17 14C17.5523 14 18 14.4477 18 15C18 15.5523 17.5523 16 17 16H7C6.44772 16 6 15.5523 6 15C6 14.4477 6.44772 14 7 14H17ZM3 9C3.55228 9 4 9.44772 4 10C4 10.5523 3.55228 11 3 11C2.44772 11 2 10.5523 2 10C2 9.44772 2.44772 9 3 9ZM17 9C17.5523 9 18 9.44772 18 10C18 10.5523 17.5523 11 17 11H7C6.44772 11 6 10.5523 6 10C6 9.44772 6.44772 9 7 9H17ZM3 4C3.55228 4 4 4.44772 4 5C4 5.55228 3.55228 6 3 6C2.44772 6 2 5.55228 2 5C2 4.44772 2.44772 4 3 4ZM17 4C17.5523 4 18 4.44772 18 5C18 5.55228 17.5523 6 17 6H7C6.44772 6 6 5.55228 6 5C6 4.44772 6.44772 4 7 4H17Z" fill="#022658"/></svg>',

  list_number:
    '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M17 14C17.5523 14 18 14.4477 18 15C18 15.5523 17.5523 16 17 16H7C6.44772 16 6 15.5523 6 15C6 14.4477 6.44772 14 7 14H17ZM17 9C17.5523 9 18 9.44772 18 10C18 10.5523 17.5523 11 17 11H7C6.44772 11 6 10.5523 6 10C6 9.44772 6.44772 9 7 9H17ZM17 4C17.5523 4 18 4.44772 18 5C18 5.55228 17.5523 6 17 6H7C6.44772 6 6 5.55228 6 5C6 4.44772 6.44772 4 7 4H17Z" fill="#022658"/><path d="M2.652 6H3.416V3.18H2.852L2.032 3.412L2.18 4.012L2.652 3.904V6Z" fill="#022658"/><path d="M2.132 11H4.348V10.368H3.192L3.672 10.032C4.088 9.732 4.32 9.5 4.32 9.052V9.044C4.32 8.516 3.9 8.152 3.288 8.152C2.744 8.152 2.448 8.364 2.148 8.752L2.672 9.188C2.892 8.936 3.04 8.828 3.228 8.828C3.416 8.828 3.54 8.936 3.54 9.112C3.54 9.28 3.44 9.412 3.18 9.616L2.132 10.416V11Z" fill="#022658"/><path d="M3.264 16.056C3.888 16.056 4.344 15.692 4.344 15.124V15.116C4.344 14.64 4.02 14.416 3.62 14.328L4.288 13.756V13.2H2.264V13.828H3.336L2.692 14.408L2.804 14.812H3.132C3.424 14.812 3.588 14.92 3.588 15.1V15.108C3.588 15.28 3.448 15.392 3.228 15.392C2.972 15.392 2.76 15.276 2.56 15.072L2.072 15.56C2.344 15.86 2.724 16.056 3.264 16.056Z" fill="#022658"/></svg>',

  indent_down:
    '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M17 16C17.5523 16 18 16.4477 18 17C18 17.5523 17.5523 18 17 18H3C2.44772 18 2 17.5523 2 17C2 16.4477 2.44772 16 3 16H17ZM5.29289 6.29289C5.89547 5.69032 6.90718 6.07233 6.99402 6.88637L7 7V13C7 13.8522 6.01449 14.2974 5.37747 13.7832L5.29289 13.7071L2.29289 10.7071C1.93241 10.3466 1.90468 9.77939 2.2097 9.3871L2.29289 9.29289L5.29289 6.29289ZM17 11C17.5523 11 18 11.4477 18 12C18 12.5523 17.5523 13 17 13H11C10.4477 13 10 12.5523 10 12C10 11.4477 10.4477 11 11 11H17ZM17 7C17.5523 7 18 7.44772 18 8C18 8.55228 17.5523 9 17 9H11C10.4477 9 10 8.55228 10 8C10 7.44772 10.4477 7 11 7H17ZM17 2C17.5523 2 18 2.44772 18 3C18 3.55228 17.5523 4 17 4H3C2.44772 4 2 3.55228 2 3C2 2.44772 2.44772 2 3 2H17Z" fill="#022658"/></svg>',

  indent_up:
    '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M17 16C17.5523 16 18 16.4477 18 17C18 17.5523 17.5523 18 17 18H3C2.44772 18 2 17.5523 2 17C2 16.4477 2.44772 16 3 16H17ZM3.70711 6.29289L6.70711 9.29289C7.09763 9.68342 7.09763 10.3166 6.70711 10.7071L3.70711 13.7071C3.07714 14.3371 2 13.8909 2 13V7C2 6.1091 3.07714 5.66293 3.70711 6.29289ZM17 11C17.5523 11 18 11.4477 18 12C18 12.5523 17.5523 13 17 13H11C10.4477 13 10 12.5523 10 12C10 11.4477 10.4477 11 11 11H17ZM17 7C17.5523 7 18 7.44772 18 8C18 8.55228 17.5523 9 17 9H11C10.4477 9 10 8.55228 10 8C10 7.44772 10.4477 7 11 7H17ZM17 2C17.5523 2 18 2.44772 18 3C18 3.55228 17.5523 4 17 4H3C2.44772 4 2 3.55228 2 3C2 2.44772 2.44772 2 3 2H17Z" fill="#022658"/></svg>',

  image:
    '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M15 2C16.6569 2 18 3.34315 18 5V15C18 16.6569 16.6569 18 15 18H5C3.34315 18 2 16.6569 2 15V5C2 3.34315 3.34315 2 5 2H15ZM15 4H5C4.44772 4 4 4.44772 4 5V12.697L6.18627 9.41876C6.50017 8.9793 7.09949 8.87371 7.54124 9.15898L7.64018 9.23178L12.7853 13.5193L16 7.745V5C16 4.44772 15.5523 4 15 4ZM6.5 8C7.32843 8 8 7.32843 8 6.5C8 5.67157 7.32843 5 6.5 5C5.67157 5 5 5.67157 5 6.5C5 7.32843 5.67157 8 6.5 8Z" /></svg>',

  strike:
    '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M11 13V17C11 17.5523 10.5523 18 10 18C9.44772 18 9 17.5523 9 17V13H11ZM3 11C2.44772 11 2 10.5523 2 10C2 9.44772 2.44772 9 3 9H17C17.5523 9 18 9.44772 18 10C18 10.5523 17.5523 11 17 11H3ZM15 2C15.5523 2 16 2.44772 16 3C16 3.55228 15.5523 4 15 4H11V7H9V4H5C4.44772 4 4 3.55228 4 3C4 2.44772 4.44772 2 5 2H15Z"/></svg>',

  underline:
    '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M14.9933 2.88338C14.9355 2.38604 14.5128 2 14 2C13.4477 2 13 2.44772 13 3V9L12.9949 9.17627C12.9037 10.7511 11.5977 12 10 12C8.34315 12 7 10.6569 7 9V3L6.99327 2.88338C6.93551 2.38604 6.51284 2 6 2C5.44772 2 5 2.44772 5 3V9L5.00462 9.21689C5.11818 11.8777 7.31125 14 10 14C12.7614 14 15 11.7614 15 9V3L14.9933 2.88338ZM15 17C15 16.4477 14.5523 16 14 16H6C5.44772 16 5 16.4477 5 17C5 17.5523 5.44772 18 6 18H14C14.5523 18 15 17.5523 15 17Z" /></svg>',

  video:
    '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M15 2C16.6569 2 18 3.34315 18 5V15C18 16.6569 16.6569 18 15 18H5C3.34315 18 2 16.6569 2 15V5C2 3.34315 3.34315 2 5 2H15ZM4 14V15C4 15.5523 4.44772 16 5 16H6V14H4ZM12 11H8V16H12V11ZM16 14H14V16H15C15.5523 16 16 15.5523 16 15V14ZM16 8H14V12H16V8ZM12 4H8V9H12V4ZM15 4H14V6H16V5C16 4.44772 15.5523 4 15 4ZM6 4H5C4.44772 4 4 4.44772 4 5V6H6V4ZM4 12H6V8H4V12Z" /></svg>',
  clean:
    '<svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.41593 11.1665H9.12505L9.36619 10.4433L7.61906 9.0936L6.9287 11.1665H6.08261C5.9721 11.1665 5.86612 11.2104 5.78798 11.2886C5.70984 11.3667 5.66594 11.4727 5.66594 11.5832V12.4165C5.66594 12.527 5.70984 12.633 5.78798 12.7111C5.86612 12.7893 5.9721 12.8332 6.08261 12.8332H9.41593C9.52644 12.8332 9.63242 12.7893 9.71056 12.7111C9.7887 12.633 9.8326 12.527 9.8326 12.4165V11.5832C9.8326 11.4727 9.7887 11.3667 9.71056 11.2886C9.63242 11.2104 9.52644 11.1665 9.41593 11.1665ZM17.1716 12.2629L10.4836 7.09335L11.7641 3.24987H14.8326V4.0832C14.8326 4.19371 14.8765 4.29969 14.9546 4.37783C15.0328 4.45597 15.1387 4.49987 15.2493 4.49987H16.0826C16.1931 4.49987 16.2991 4.45597 16.3772 4.37783C16.4553 4.29969 16.4992 4.19371 16.4992 4.0832V1.58321C16.4992 1.4727 16.4553 1.36672 16.3772 1.28858C16.2991 1.21044 16.1931 1.16654 16.0826 1.16654H5.24928C5.13877 1.16654 5.03279 1.21044 4.95465 1.28858C4.87651 1.36672 4.83261 1.4727 4.83261 1.58321V2.72643L1.84981 0.421233C1.80661 0.387615 1.7572 0.36284 1.70442 0.348323C1.65164 0.333806 1.59652 0.329832 1.5422 0.336628C1.48788 0.343424 1.43544 0.360856 1.38786 0.387929C1.34028 0.415002 1.2985 0.451185 1.26492 0.49441L0.753719 1.15248C0.685907 1.23972 0.65552 1.35031 0.669243 1.45995C0.682966 1.56959 0.739675 1.66928 0.826896 1.73711L16.1487 13.5787C16.1919 13.6124 16.2413 13.6371 16.2941 13.6517C16.3469 13.6662 16.402 13.6701 16.4563 13.6633C16.5107 13.6566 16.5631 13.6391 16.6107 13.612C16.6583 13.585 16.7 13.5488 16.7336 13.5056L17.2451 12.8475C17.3129 12.7602 17.3432 12.6496 17.3294 12.54C17.3157 12.4303 17.2589 12.3307 17.1716 12.2629ZM8.73651 5.74361L6.49928 4.01445V3.24987H9.56776L8.73651 5.74361Z"/></svg>',

  info: '<path fill-rule="evenodd" clip-rule="evenodd" d="M6.9999 1.3999C10.0927 1.3999 12.5999 3.90711 12.5999 6.9999C12.5999 10.0927 10.0927 12.5999 6.9999 12.5999C3.90711 12.5999 1.3999 10.0927 1.3999 6.9999C1.3999 3.90711 3.90711 1.3999 6.9999 1.3999ZM6.9999 6.2999C6.64092 6.2999 6.34505 6.57013 6.30461 6.91827L6.2999 6.9999V9.7999C6.2999 10.1865 6.6133 10.4999 6.9999 10.4999C7.35889 10.4999 7.65476 10.2297 7.69519 9.88154L7.6999 9.7999V6.9999C7.6999 6.6133 7.3865 6.2999 6.9999 6.2999ZM6.9999 3.4999C6.6133 3.4999 6.2999 3.8133 6.2999 4.1999C6.2999 4.5865 6.6133 4.8999 6.9999 4.8999C7.3865 4.8999 7.6999 4.5865 7.6999 4.1999C7.6999 3.8133 7.3865 3.4999 6.9999 3.4999Z"/>',

  caution:
    '<path fill-rule="evenodd" clip-rule="evenodd" d="M10.9427 2.22307C11.3508 2.4271 11.6817 2.75797 11.8857 3.16603L17.7769 14.9485C18.2977 15.9901 17.8755 17.2566 16.834 17.7774C16.5412 17.9238 16.2183 18 15.891 18L4.10854 18C2.94402 18 2 17.056 2 15.8915C2 15.5641 2.07621 15.2413 2.2226 14.9485L8.11384 3.16603C8.63462 2.12446 9.90116 1.70228 10.9427 2.22307ZM10 14C9.44772 14 9 14.4477 9 15C9 15.5523 9.44772 16 10 16C10.5523 16 11 15.5523 11 15C11 14.4477 10.5523 14 10 14ZM10 6C9.48716 6 9.06449 6.38604 9.00673 6.88338L9 7L9 11C9 11.5523 9.44772 12 10 12C10.5128 12 10.9355 11.614 10.9933 11.1166L11 11V7C11 6.44772 10.5523 6 10 6Z"/>',
  // editor: {
  //   align_center:
  //     '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M3 3C2.44772 3 2 3.44772 2 4C2 4.55228 2.44772 5 3 5H17C17.5523 5 18 4.55228 18 4C18 3.44772 17.5523 3 17 3H3ZM5 15C4.44772 15 4 15.4477 4 16C4 16.5523 4.44772 17 5 17H15C15.5523 17 16 16.5523 16 16C16 15.4477 15.5523 15 15 15H5ZM2 12C2 11.4477 2.44772 11 3 11H17C17.5523 11 18 11.4477 18 12C18 12.5523 17.5523 13 17 13H3C2.44772 13 2 12.5523 2 12ZM5 7C4.44772 7 4 7.44772 4 8C4 8.55228 4.44772 9 5 9H15C15.5523 9 16 8.55228 16 8C16 7.44772 15.5523 7 15 7H5Z" fill="#022658"/></svg>',

  //   align_justify:
  //     '<path fill-rule="evenodd" clip-rule="evenodd" d="M17 15C17.5523 15 18 15.4477 18 16C18 16.5523 17.5523 17 17 17H3C2.44772 17 2 16.5523 2 16C2 15.4477 2.44772 15 3 15H17ZM17 11C17.5523 11 18 11.4477 18 12C18 12.5523 17.5523 13 17 13H3C2.44772 13 2 12.5523 2 12C2 11.4477 2.44772 11 3 11H17ZM17 7C17.5523 7 18 7.44772 18 8C18 8.55228 17.5523 9 17 9H3C2.44772 9 2 8.55228 2 8C2 7.44772 2.44772 7 3 7H17ZM17 3C17.5523 3 18 3.44772 18 4C18 4.55228 17.5523 5 17 5H3C2.44772 5 2 4.55228 2 4C2 3.44772 2.44772 3 3 3H17Z"/>',

  //   align_left:
  //     '<path fill-rule="evenodd" clip-rule="evenodd" d="M3 3C2.44772 3 2 3.44772 2 4C2 4.55228 2.44772 5 3 5H17C17.5523 5 18 4.55228 18 4C18 3.44772 17.5523 3 17 3H3ZM3 15C2.44772 15 2 15.4477 2 16C2 16.5523 2.44772 17 3 17H13C13.5523 17 14 16.5523 14 16C14 15.4477 13.5523 15 13 15H3ZM2 12C2 11.4477 2.44772 11 3 11H17C17.5523 11 18 11.4477 18 12C18 12.5523 17.5523 13 17 13H3C2.44772 13 2 12.5523 2 12ZM3 7C2.44772 7 2 7.44772 2 8C2 8.55228 2.44772 9 3 9H13C13.5523 9 14 8.55228 14 8C14 7.44772 13.5523 7 13 7H3Z"/>',

  //   align_right:
  //     '<path fill-rule="evenodd" clip-rule="evenodd" d="M3 3C2.44772 3 2 3.44772 2 4C2 4.55228 2.44772 5 3 5H17C17.5523 5 18 4.55228 18 4C18 3.44772 17.5523 3 17 3H3ZM7 15C6.44772 15 6 15.4477 6 16C6 16.5523 6.44771 17 7 17H17C17.5523 17 18 16.5523 18 16C18 15.4477 17.5523 15 17 15H7ZM2 12C2 11.4477 2.44772 11 3 11H17C17.5523 11 18 11.4477 18 12C18 12.5523 17.5523 13 17 13H3C2.44772 13 2 12.5523 2 12ZM7 7C6.44772 7 6 7.44772 6 8C6 8.55228 6.44771 9 7 9H17C17.5523 9 18 8.55228 18 8C18 7.44772 17.5523 7 17 7H7Z"/>',

  //   bold: '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fill-rule="evenodd" clip-rule="evenodd" d="M5 18C4.44772 18 4 17.5523 4 17V3C4 2.44772 4.44772 2 5 2H10C12.2463 2 14 4.04599 14 6.5C14 7.50829 13.7039 8.44769 13.1993 9.20461C14.8407 9.77921 16 11.5047 16 13.5C16 15.8818 14.3479 17.8793 12.1969 17.9947L12 18H5ZM12.1327 11.0056L12 11H6V16H12C13.0674 16 14 14.912 14 13.5C14 12.1468 13.1435 11.0912 12.1327 11.0056ZM10 4H6V9H10C11.0674 9 12 7.91199 12 6.5C12 5.14685 11.1435 4.09123 10.1327 4.00561L10 4Z" fill="#022658"/></svg>',

  //   color:
  //     '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M5.09252 14.9999C5.66489 14.9999 5.95717 14.7795 6.16421 14.1673L7.26023 11.0695H12.7039L13.8121 14.1673C14.0191 14.7795 14.3114 14.9999 14.8838 14.9999C15.4561 14.9999 15.8337 14.6571 15.8337 14.0938C15.8337 13.9102 15.7971 13.7388 15.7119 13.5061L11.4008 1.84952C11.1451 1.16386 10.6823 0.833252 9.98814 0.833252C9.29399 0.833252 8.84341 1.1516 8.59983 1.83728L4.27658 13.5183C4.20351 13.751 4.16699 13.9224 4.16699 14.1061C4.16699 14.6571 4.52016 14.9999 5.09252 14.9999ZM7.79606 9.40427L9.9516 3.25763H10.0125L12.168 9.40427H7.79606Z" fill="#022658"/><rect x="3.3335" y="16.6665" width="13.3333" height="1.66667" rx="0.833334" fill="#022658"/></svg>',
  //   background:
  //     '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M16 11.6C16 9.24359 14 6.04359 10 2C6 6.04359 4 9.24359 4 11.6C4 15.1346 6.68629 18 10 18L10.22 17.9958C13.4318 17.8723 16 15.0561 16 11.6ZM10 4.904V16C7.82074 16 6 14.0579 6 11.6C6 10.167 7.29785 7.88569 9.97057 4.93615L10 4.904Z" fill="#3B3B3B"/></svg>',

  //   indent_down:
  //     '<path fill-rule="evenodd" clip-rule="evenodd" d="M17 16C17.5523 16 18 16.4477 18 17C18 17.5523 17.5523 18 17 18H3C2.44772 18 2 17.5523 2 17C2 16.4477 2.44772 16 3 16H17ZM5.29289 6.29289C5.89547 5.69032 6.90718 6.07233 6.99402 6.88637L7 7V13C7 13.8522 6.01449 14.2974 5.37747 13.7832L5.29289 13.7071L2.29289 10.7071C1.93241 10.3466 1.90468 9.77939 2.2097 9.3871L2.29289 9.29289L5.29289 6.29289ZM17 11C17.5523 11 18 11.4477 18 12C18 12.5523 17.5523 13 17 13H11C10.4477 13 10 12.5523 10 12C10 11.4477 10.4477 11 11 11H17ZM17 7C17.5523 7 18 7.44772 18 8C18 8.55228 17.5523 9 17 9H11C10.4477 9 10 8.55228 10 8C10 7.44772 10.4477 7 11 7H17ZM17 2C17.5523 2 18 2.44772 18 3C18 3.55228 17.5523 4 17 4H3C2.44772 4 2 3.55228 2 3C2 2.44772 2.44772 2 3 2H17Z"/>',

  //   indent_up:
  //     '<path fill-rule="evenodd" clip-rule="evenodd" d="M17 16C17.5523 16 18 16.4477 18 17C18 17.5523 17.5523 18 17 18H3C2.44772 18 2 17.5523 2 17C2 16.4477 2.44772 16 3 16H17ZM3.70711 6.29289L6.70711 9.29289C7.09763 9.68342 7.09763 10.3166 6.70711 10.7071L3.70711 13.7071C3.07714 14.3371 2 13.8909 2 13V7C2 6.1091 3.07714 5.66293 3.70711 6.29289ZM17 11C17.5523 11 18 11.4477 18 12C18 12.5523 17.5523 13 17 13H11C10.4477 13 10 12.5523 10 12C10 11.4477 10.4477 11 11 11H17ZM17 7C17.5523 7 18 7.44772 18 8C18 8.55228 17.5523 9 17 9H11C10.4477 9 10 8.55228 10 8C10 7.44772 10.4477 7 11 7H17ZM17 2C17.5523 2 18 2.44772 18 3C18 3.55228 17.5523 4 17 4H3C2.44772 4 2 3.55228 2 3C2 2.44772 2.44772 2 3 2H17Z"/>',

  //   italic:
  //     '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M16 2C16.5523 2 17 2.44772 17 3C17 3.55228 16.5523 4 16 4H12.754L9.325 16H12C12.5523 16 13 16.4477 13 17C13 17.5523 12.5523 18 12 18H4C3.44772 18 3 17.5523 3 17C3 16.4477 3.44772 16 4 16H7.245L10.674 4H8C7.44772 4 7 3.55228 7 3C7 2.44772 7.44772 2 8 2H16Z" fill="#022658"/></svg>',

  //   link: '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M3.47186 9.42136L4.31457 8.57864C4.70707 8.18615 5.34343 8.18615 5.73593 8.57864C6.10039 8.9431 6.12643 9.51784 5.81403 9.91234L5.73593 10L4.89322 10.8427C3.71573 12.0202 3.71573 13.9293 4.89322 15.1068C6.02866 16.2422 7.84437 16.2828 9.0284 15.2284L9.15729 15.1068L10 14.2641C10.3925 13.8716 11.0289 13.8716 11.4214 14.2641C11.7858 14.6285 11.8119 15.2033 11.4995 15.5978L11.4214 15.6854L10.5786 16.5281C8.61616 18.4906 5.43435 18.4906 3.47186 16.5281C1.56102 14.6173 1.51074 11.5505 3.32101 9.57878L3.47186 9.42136ZM9.42136 3.47186C11.3838 1.50938 14.5657 1.50938 16.5281 3.47186C18.4906 5.43435 18.4906 8.61616 16.5281 10.5786L15.6854 11.4214C15.2929 11.8139 14.6566 11.8139 14.2641 11.4214C13.8716 11.0289 13.8716 10.3925 14.2641 10L15.1068 9.15729C16.2843 7.9798 16.2843 6.07071 15.1068 4.89322C13.9293 3.71573 12.0202 3.71573 10.8427 4.89322L10 5.73593C9.6075 6.12843 8.97114 6.12843 8.57864 5.73593C8.18615 5.34343 8.18615 4.70707 8.57864 4.31457L9.42136 3.47186ZM10.4214 8.15729C10.8139 7.76479 11.4502 7.76479 11.8427 8.15729C12.2352 8.54978 12.2352 9.18615 11.8427 9.57864L9.57864 11.8427C9.18615 12.2352 8.54978 12.2352 8.15729 11.8427C7.76479 11.4502 7.76479 10.8139 8.15729 10.4214L10.4214 8.15729Z" fill="#022658"/></svg>',
  //   list_bullet:
  //     '<path fill-rule="evenodd" clip-rule="evenodd" d="M3 14C3.55228 14 4 14.4477 4 15C4 15.5523 3.55228 16 3 16C2.44772 16 2 15.5523 2 15C2 14.4477 2.44772 14 3 14ZM17 14C17.5523 14 18 14.4477 18 15C18 15.5523 17.5523 16 17 16H7C6.44772 16 6 15.5523 6 15C6 14.4477 6.44772 14 7 14H17ZM3 9C3.55228 9 4 9.44772 4 10C4 10.5523 3.55228 11 3 11C2.44772 11 2 10.5523 2 10C2 9.44772 2.44772 9 3 9ZM17 9C17.5523 9 18 9.44772 18 10C18 10.5523 17.5523 11 17 11H7C6.44772 11 6 10.5523 6 10C6 9.44772 6.44772 9 7 9H17ZM3 4C3.55228 4 4 4.44772 4 5C4 5.55228 3.55228 6 3 6C2.44772 6 2 5.55228 2 5C2 4.44772 2.44772 4 3 4ZM17 4C17.5523 4 18 4.44772 18 5C18 5.55228 17.5523 6 17 6H7C6.44772 6 6 5.55228 6 5C6 4.44772 6.44772 4 7 4H17Z"/>',

  //   list_number:
  //     '<path fill-rule="evenodd" clip-rule="evenodd" d="M17 14C17.5523 14 18 14.4477 18 15C18 15.5523 17.5523 16 17 16H7C6.44772 16 6 15.5523 6 15C6 14.4477 6.44772 14 7 14H17ZM17 9C17.5523 9 18 9.44772 18 10C18 10.5523 17.5523 11 17 11H7C6.44772 11 6 10.5523 6 10C6 9.44772 6.44772 9 7 9H17ZM17 4C17.5523 4 18 4.44772 18 5C18 5.55228 17.5523 6 17 6H7C6.44772 6 6 5.55228 6 5C6 4.44772 6.44772 4 7 4H17Z"/>',

  //   image:
  //     '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M15 2C16.6569 2 18 3.34315 18 5V15C18 16.6569 16.6569 18 15 18H5C3.34315 18 2 16.6569 2 15V5C2 3.34315 3.34315 2 5 2H15ZM15 4H5C4.44772 4 4 4.44772 4 5V12.697L6.18627 9.41876C6.50017 8.9793 7.09949 8.87371 7.54124 9.15898L7.64018 9.23178L12.7853 13.5193L16 7.745V5C16 4.44772 15.5523 4 15 4ZM6.5 8C7.32843 8 8 7.32843 8 6.5C8 5.67157 7.32843 5 6.5 5C5.67157 5 5 5.67157 5 6.5C5 7.32843 5.67157 8 6.5 8Z" fill="#022658"/></svg>',

  //   strike:
  //     '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M11 13V17C11 17.5523 10.5523 18 10 18C9.44772 18 9 17.5523 9 17V13H11ZM3 11C2.44772 11 2 10.5523 2 10C2 9.44772 2.44772 9 3 9H17C17.5523 9 18 9.44772 18 10C18 10.5523 17.5523 11 17 11H3ZM15 2C15.5523 2 16 2.44772 16 3C16 3.55228 15.5523 4 15 4H11V7H9V4H5C4.44772 4 4 3.55228 4 3C4 2.44772 4.44772 2 5 2H15Z" fill="#022658"/></svg>',

  //   underline:
  //     '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M14.9933 2.88338C14.9355 2.38604 14.5128 2 14 2C13.4477 2 13 2.44772 13 3V9L12.9949 9.17627C12.9037 10.7511 11.5977 12 10 12C8.34315 12 7 10.6569 7 9V3L6.99327 2.88338C6.93551 2.38604 6.51284 2 6 2C5.44772 2 5 2.44772 5 3V9L5.00462 9.21689C5.11818 11.8777 7.31125 14 10 14C12.7614 14 15 11.7614 15 9V3L14.9933 2.88338ZM15 17C15 16.4477 14.5523 16 14 16H6C5.44772 16 5 16.4477 5 17C5 17.5523 5.44772 18 6 18H14C14.5523 18 15 17.5523 15 17Z" fill="#022658"/></svg>',

  //   video:
  //     '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M15 2C16.6569 2 18 3.34315 18 5V15C18 16.6569 16.6569 18 15 18H5C3.34315 18 2 16.6569 2 15V5C2 3.34315 3.34315 2 5 2H15ZM4 14V15C4 15.5523 4.44772 16 5 16H6V14H4ZM12 11H8V16H12V11ZM16 14H14V16H15C15.5523 16 16 15.5523 16 15V14ZM16 8H14V12H16V8ZM12 4H8V9H12V4ZM15 4H14V6H16V5C16 4.44772 15.5523 4 15 4ZM6 4H5C4.44772 4 4 4.44772 4 5V6H6V4ZM4 12H6V8H4V12Z" fill="#022658"/></svg>',
  // },
};

export default iconList;

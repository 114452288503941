import React, {
  useState, useEffect, createContext, useContext,
} from 'react';
import Toast from 'components/Toast';

const ToastContext = createContext<any>([]);

export const ToastProvider: React.FC<React.ReactNode> = ({ children }) => {
  const [isToastOpen, setIsToastOpen] = useState<boolean>(false);
  const [toast, setToast] = useState<{
    type: string;
    message: string;
    link: string;
    linkTitle: string;
  } | null>();

  const openToastMessage = (
    type: string,
    message: string,
    link: string,
    linkTitle: string,
  ) => {
    setToast({
      type,
      message,
      link,
      linkTitle,
    });
    setIsToastOpen(true);
  };

  const closeToastMessage = () => {
    setIsToastOpen(false);
  };

  useEffect(() => {
    if (isToastOpen) {
      const timer = setTimeout(() => {
        closeToastMessage();
      }, 3000);

      return () => {
        clearTimeout(timer);
      };
    }
    return undefined;
  }, [isToastOpen]);

  return (
    <ToastContext.Provider value={{ openToastMessage, closeToastMessage }}>
      {children}
      {isToastOpen && toast && toast.message && (
        <Toast
          type={toast.type}
          message={toast.message}
          link={toast.link}
          linkTitle={toast.linkTitle}
          onCloseHandler={closeToastMessage}
        />
      )}
    </ToastContext.Provider>
  );
};

export const useToastContext = () => useContext(ToastContext);

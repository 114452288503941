import React, { createContext, useContext, useReducer } from 'react';
import { GET_TAGS } from 'graphql/queries/GET_TAGS';
import { useGraphQLQuery } from 'hooks/useGraphQLQuery';
import reducer from '../reducer/companyReducer';

const ActionType: any = {
  GET_TAGS: 'GET_TAGS',
  ADD_TAG: 'ADD_TAG',
  REMOVE_TAG: 'REMOVE_TAG',
};

interface ICompany {
  myCompany: {
    tags: { id: number; label: string; value: string }[];
  };
}

const initialState: ICompany = {
  myCompany: {
    tags: [],
  },
};

const CompanyContext = createContext<any>([]);

export const CompanyProvider: React.FC<React.ReactNode> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const getCompanyTags = () => {
    useGraphQLQuery(GET_TAGS, {}).then((dta: any) => {
      if (dta.getTags.success) {
        dispatch({
          type: ActionType.GET_TAGS,
          payload: {
            tags: dta.getTags.data.tags.map((tag: string, idx: number) => ({
              id: idx,
              label: tag,
              value: tag,
            })),
          },
        });
      }
    });
  };

  const addTag = (tag: string) => {
    const preexistedTag = state.myCompany.tags.find(
      (tagObj: { id: number; label: string; value: string }) => tagObj.value === tag,
    );

    if (preexistedTag === undefined) {
      dispatch({
        type: ActionType.ADD_TAG,
        payload: {
          tags: [
            ...state.myCompany.tags,
            { id: state.myCompany.tags.length, label: tag, value: tag },
          ],
        },
      });
    }
  };

  const removeTag = (tag: string) => {
    const preexistedTag = state.myCompany.tags.find(
      (tagObj: { id: number; label: string; value: string }) => tagObj.value === tag,
    );

    if (preexistedTag !== undefined) {
      dispatch({
        type: ActionType.REMOVE_TAG,
        payload: {
          tags: [
            ...state.myCompany.tags.filter(
              (tagObj: { id: number; label: string; value: string }) => tagObj.value !== tag,
            ),
          ],
        },
      });
    }
  };

  return (
    <CompanyContext.Provider
      value={{
        ...state,
        getCompanyTags,
        addTag,
        removeTag,
        dispatch,
      }}
    >
      {children}
    </CompanyContext.Provider>
  );
};

export const useCompanyContext = () => useContext(CompanyContext);

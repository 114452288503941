import styled from 'styled-components';
import flexGen from 'utils/flexGen';
import toRem from 'utils/toRem';

interface IStyledIconWrapper {
  paddingRight: number;
  paddingLeft: number;
  paddingTop: number;
  paddingBottom: number;
  marginRight: number;
  marginLeft: number;
  marginTop: number;
  marginBottom: number;
  tooltip: boolean;
}

export const StyledIconWrapper = styled.div<IStyledIconWrapper>`
  ${flexGen('center', 'center')}
  padding-top: ${(props) => toRem(props.paddingTop)};
  padding-bottom: ${(props) => toRem(props.paddingBottom)};
  padding-left: ${(props) => toRem(props.paddingLeft)};
  padding-right: ${(props) => toRem(props.paddingRight)};
  margin-top: ${(props) => toRem(props.marginTop)};
  margin-bottom: ${(props) => toRem(props.marginBottom)};
  margin-left: ${(props) => toRem(props.marginLeft)};
  margin-right: ${(props) => toRem(props.marginRight)};

  ${(props) => props.tooltip
    && `&:hover {
      &+div {
        opacity: 1;
      }
    }`}
`;

import React from 'react';
import Icon from 'components/Icon';
import { StyledButtonIcon } from './styles';

interface ButtonIconProps {
  icon: string;
  margin: number;
  color: string;
  width?: number;
  height?: number;
  viewBox?: string;
  onClick(): any;
  tooltip?: boolean
}

const ButtonIcon: React.FC<ButtonIconProps> = ({
  icon,
  color,
  margin,
  width,
  height,
  viewBox,
  onClick,
  tooltip = false,
}) => (
  <StyledButtonIcon margin={margin} onClick={onClick} tooltip={tooltip}>
    <Icon
      icon={icon}
      color={color}
      viewbox={viewBox}
      width={width}
      height={height}
    />
  </StyledButtonIcon>
);

ButtonIcon.defaultProps = {
  viewBox: '0 0 20 20',
  width: 20,
  height: 20,
  tooltip: false,
};

export default ButtonIcon;

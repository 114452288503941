import React, { useEffect, lazy, Suspense } from 'react';
import Spinner from 'components/Spinner';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import ProtectedRoute from 'ProtectedRoute';
import Perspective from 'pages/Perspective';

import Navigator from 'components/Navigator';
import NavContainer from 'components/NavContainer';
import { clearStorage } from 'utils/clearStorage';
import { deleteAuthCookies } from 'utils/deleteAuthCookies';
import { checkAuthCookies } from 'utils/checkAuthCookies';
import { ToastProvider } from 'state/context/toastContext';

import { useUserContext } from 'state/context/userContext';
import { useCompanyContext } from 'state/context/companyContext';
import MerchantCheck from 'components/MerchantCheck';
import path from 'utils/path';
import { StyledAppContainer } from './styles';

const Auth = React.lazy(() => import('pages/Auth'));
const NewAnnouncement = lazy(() => import('pages/NewAnnouncement'));
const NewMessage = lazy(() => import('pages/NewMessage'));
const NewTemplate = lazy(() => import('pages/NewTemplate'));
const Template = lazy(() => import('pages/Template'));
const Message = lazy(() => import('pages/Message'));
const Announcements = lazy(() => import('pages/Announcements'));
const Announcement = lazy(() => import('pages/Announcement'));
const Archive = lazy(() => import('pages/Archive'));
const Inbox = lazy(() => import('pages/Inbox'));
const Templates = lazy(() => import('pages/Templates'));
const PublisherContactList = lazy(() => import('pages/PublisherContactList'));
const Callback = lazy(() => import('pages/Callback'));
const Search = lazy(() => import('pages/Search'));
const MerchantView = lazy(() => import('pages/MerchantView'));
const PublisherView = lazy(() => import('pages/PublisherView'));
const ServerError = lazy(() => import('pages/ServerError'));

const Routes = () => {
  const { authenticate, me } = useUserContext();
  const { getCompanyTags } = useCompanyContext();

  useEffect(() => {
    clearStorage();
    authenticate();
    deleteAuthCookies();
    document.addEventListener('mousemove', () => {
      checkAuthCookies('app.fintelconnect.com/login');
    });
  }, []);

  useEffect(() => {
    if (me.companyId) {
      getCompanyTags();
    }
  }, [me.companyId]);

  return (
    <BrowserRouter>
      <ToastProvider>
        <StyledAppContainer>
          <Navigator />

          <NavContainer marginWidth={0}>
            <Suspense fallback={<Spinner />}>
              <Switch>
                <Route exact path="/auth">
                  <Auth />
                </Route>
                <Route exact path="/callback">
                  <Callback />
                </Route>
                <ProtectedRoute
                  exact
                  path={[path.inbox, '/inbox/viewas/:companyId']}
                >
                  <MerchantCheck user={me} path={path.inbox}>
                    <Inbox />
                  </MerchantCheck>
                </ProtectedRoute>
                <ProtectedRoute
                  exact
                  path={['/announcements', '/announcements/viewas/:companyId']}
                >
                  <MerchantCheck user={me} path={path.announcements}>
                    <Announcements />
                  </MerchantCheck>

                </ProtectedRoute>
                <ProtectedRoute
                  exact
                  path={['/message/:messageId', '/message/viewas/:messageId']}
                >
                  <MerchantCheck user={me} path={path.message}>
                    <Message />
                  </MerchantCheck>

                </ProtectedRoute>
                <ProtectedRoute
                  exact
                  path={[
                    '/announcement/:announcementId',
                    '/announcement/viewas/:announcementId',
                  ]}
                >
                  <MerchantCheck user={me} path={path.announcement}>
                    <Announcement />
                  </MerchantCheck>

                </ProtectedRoute>

                <ProtectedRoute
                  exact
                  path={['/archive', '/archive/viewas/:companyId']}
                >
                  <MerchantCheck user={me} path={path.archive}>
                    <Archive />
                  </MerchantCheck>

                </ProtectedRoute>

                <ProtectedRoute
                  exact
                  path={['/templates', '/templates/viewas/:companyId']}
                >
                  <MerchantCheck user={me} path={path.templates}>
                    <Templates />
                  </MerchantCheck>

                </ProtectedRoute>

                <ProtectedRoute
                  exact
                  path={[
                    '/template/:templateId',
                    '/template/viewas/:templateId',
                  ]}
                >
                  <MerchantCheck user={me} path={path.template}>
                    <Template />
                  </MerchantCheck>

                </ProtectedRoute>

                <ProtectedRoute
                  exact
                  path={['/contact-list', '/contact-list/viewas/:companyId']}
                >
                  <PublisherContactList />
                </ProtectedRoute>

                <ProtectedRoute
                  exact
                  path={['/search', '/search/viewas/:companyId']}
                >
                  <MerchantCheck user={me} path={path.inbox}>
                    <Search />
                  </MerchantCheck>

                </ProtectedRoute>

                <ProtectedRoute
                  exact
                  path={['/newmessage', '/newmessage/viewas']}
                >
                  <MerchantCheck user={me} path={path.newMessage}>
                    <NewMessage />
                  </MerchantCheck>

                </ProtectedRoute>

                <ProtectedRoute
                  exact
                  path={['/newannouncement', '/newannouncement/viewas']}
                >
                  <MerchantCheck user={me} path={path.newAnnouncement}>
                    <NewAnnouncement />
                  </MerchantCheck>

                </ProtectedRoute>

                <ProtectedRoute
                  exact
                  path={['/newtemplate', '/newtemplate/viewas']}
                >
                  <MerchantCheck user={me} path={path.newTemplate}>
                    <NewTemplate />
                  </MerchantCheck>

                </ProtectedRoute>

                <ProtectedRoute
                  exact
                  path="/merchantview"
                >
                  {/* <MerchantCheck user={me} path={path.inbox}> */}
                  <MerchantView />
                  {/* </MerchantCheck> */}

                </ProtectedRoute>
                <ProtectedRoute
                  exact
                  path="/publisherview"
                >
                  {/* <MerchantCheck user={me} path={path.inbox}> */}
                  <PublisherView />
                  {/* </MerchantCheck> */}

                </ProtectedRoute>
                <ProtectedRoute
                  exact
                  path="/perspective/:companyId"
                >
                  {/* <MerchantCheck user={me} path={path.inbox}> */}
                  <Perspective />
                  {/* </MerchantCheck> */}

                </ProtectedRoute>
                <Route path="/servererror" component={ServerError} />
                {/* <Route path="*" component={UrlNotFound} /> */}
              </Switch>
            </Suspense>
          </NavContainer>
        </StyledAppContainer>
      </ToastProvider>
    </BrowserRouter>
  );
};
export default Routes;

import styled from 'styled-components';
import flexGen from 'utils/flexGen';

export const StyledAppContainer = styled.div`
  ${flexGen()}
  width: 100vw;
  height: 100vh;

  overflow-y: hidden;
`;

import React from 'react';
import {
  StyledSpinner,
  StyledSpinnerWrapper,
  StyledSpinnerDot1,
  StyledSpinnerDot2,
  StyledSpinnerDot3,
} from './styles';

const Spinner: React.FC = () => (
  <StyledSpinner>
    <StyledSpinnerWrapper>
      <StyledSpinnerDot1>
        <span />
      </StyledSpinnerDot1>
      <StyledSpinnerDot2>
        <span />
      </StyledSpinnerDot2>
      <StyledSpinnerDot3>
        <span />
      </StyledSpinnerDot3>
    </StyledSpinnerWrapper>
  </StyledSpinner>
);

export default Spinner;

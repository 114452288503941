import { gql } from '@apollo/client';

export const GET_LATEST_MESSAGES = gql`
  query latestMessages($companyId: Float!) {
    latestMessages(companyId: $companyId) {
      success
      status
      description
      data {
        size
      }
    }
  }
`;

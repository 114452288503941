import styled from 'styled-components';
import { colors, fonts } from 'styles/theme';
import Text from 'components/Text';
import flexGen from 'utils/flexGen';
import toRem from 'utils/toRem';

export const StyledLogo = styled.div`
  ${flexGen('center', 'center', 'column')}
  padding-top: ${toRem(16)};
  padding-bottom: ${toRem(16)};
`;

export const StyledLogoTitle = styled(Text)`
  font-family: ${fonts.font1};
  font-size: ${toRem(20)};
  letter-spacing: ${toRem(3)};
  margin-top: ${toRem(4)};
  color: ${colors.color2};
`;

export const StyledLogoSubtitle = styled(Text)`
  font-size: ${toRem(10)};
  letter-spacing: ${toRem(2)};
  color: ${colors.color2};
`;

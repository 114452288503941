import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  DefaultOptions,
  OperationVariables,
  DocumentNode,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { getToken } from 'utils/auth';

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
  mutate: {
    errorPolicy: 'all',
  },
};

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_URI,
});

const authLink = setContext((_, { headers }) => ({
  headers: { ...headers, authorization: `Bearer ${getToken()}` },
}));

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    addTypename: false,
  }),
  defaultOptions,
});

const query = (input: {
  query: DocumentNode;
  variables?: OperationVariables | null;
}): Promise<any> => new Promise((resolve, reject) => {
  client
    .query(input)
    .then(({ data, errors }) => {
      if (Array.isArray(errors) && errors.length > 0) {
        reject(errors[0].message);
        return;
      }
      resolve(data);
    })
    .catch((err) => {
      reject(err.message);
    });
});

const mutation = (input: {
  mutation: DocumentNode;
  variables?: OperationVariables | null;
}): Promise<any> => new Promise((resolve, reject) => {
  client
    .mutate(input)
    .then(({ data, errors }) => {
      if (Array.isArray(errors) && errors.length > 0) {
        reject(errors[0].message);
        return;
      }
      resolve(data);
    })
    .catch((err) => {
      reject(err.message);
    });
});

export default {
  client,
  query,
  mutation,
};

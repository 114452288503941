import React from 'react';
import { Link } from 'react-router-dom';
import { StyledSideMenuLinkWrapper } from './styles';

interface SideMenuLinkProps {
  to: string;
  children: React.ReactNode;
  active: boolean;
  onClick?(): void;
}

const SideMenuLink: React.FC<SideMenuLinkProps> = ({
  children,
  to,
  active,
  onClick,
}) => (
  <StyledSideMenuLinkWrapper active={active} onClick={onClick}>
    <Link to={to}>{children}</Link>
  </StyledSideMenuLinkWrapper>
);

SideMenuLink.defaultProps = {
  onClick: () => {},
};

export default SideMenuLink;

import React, { useEffect, useState } from 'react';
import { useHistory, useParams, useLocation } from 'react-router';
import Spinner from 'components/Spinner';
import { useUserContext } from 'state/context/userContext';
import { GET_COMPANY } from 'graphql/queries/GET_COMPANY';
import { useAppContext } from 'state/context/appContext';

import { useGraphQLQuery } from 'hooks/useGraphQLQuery';
import { StyledPerspectiveWrapper } from './styles';

const Perspective: React.FC = () => {
  const { me } = useUserContext();
  const { onNavMenuListChangeHandler } = useAppContext();

  const [loading, setLoading] = useState(true);

  const { companyId } = useParams<{ companyId: string }>();
  const urlQueries = new URLSearchParams(useLocation().search);
  const redirect: string | null = urlQueries.get('redirect');

  const history = useHistory();

  useEffect(() => {
    if (me.companyId) {
      useGraphQLQuery(GET_COMPANY, {
        companyId: Number(companyId),
      }).then((dta: any) => {
        setLoading(false);
        if (dta.getCompany.success) {
          localStorage.setItem('vmode', 'true');
          localStorage.setItem('cid', dta.getCompany.data.companies[0]._id);
          localStorage.setItem(
            'cname',
            dta.getCompany.data.companies[0].companyName,
          );
          localStorage.setItem(
            'ctype',
            dta.getCompany.data.companies[0].companyType,
          );
          localStorage.setItem('urlPrefix', '/viewas');

          onNavMenuListChangeHandler();
          if (redirect) {
            history.replace(
              `/${redirect}/viewas/${dta.getCompany.data.companies[0]._id}`,
            );
          } else {
            history.replace(
              `/inbox/viewas/${dta.getCompany.data.companies[0]._id}`,
            );
          }
        }
      });
    }
  }, [me]);

  const renderPerspective = () => {
    if (loading) {
      return (
        <StyledPerspectiveWrapper>
          <Spinner />
        </StyledPerspectiveWrapper>
      );
    }
    return (
      <StyledPerspectiveWrapper>
        <Spinner />
      </StyledPerspectiveWrapper>
    );
  };

  return renderPerspective();
};

export default Perspective;

import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { GET_ANNOUNCEMENTS_BY_DAYS } from 'graphql/queries/GET_ANNOUNCEMENTS_BY_DAYS';
import { GET_LATEST_MESSAGES } from 'graphql/queries/GET_LATEST_MESSAGES';
import { GET_COMPANY } from 'graphql/queries/GET_COMPANY';
import { useUserContext } from 'state/context/userContext';
import Icon from 'components/Icon';
import { colors, fonts } from 'styles/theme';
import { clearStorage } from 'utils/clearStorage';
import { getTypeFromCookie } from 'utils/getTypeFromCookie';
import Text from 'components/Text';
import Threshold from 'components/Threshold';
import Badge from 'components/Badge';
import toRem from 'utils/toRem';
import { useAppContext } from 'state/context/appContext';
import { useGraphQLQuery } from 'hooks/useGraphQLQuery';
import SideMenuLink from './SideMenuLink';
import { StyledSideMenuLinkTitle } from './SideMenuLink/styles';
import SideMenuHelpLink from './SideMenuHelpLink';
import Logo from './Logo';
import {
  StyledNavWrapper,
  StyledSideMenuRow,
  StyledSideMenuWrapper,
  StyledSideMenuNavigator,
  StyledSideMenuSimplifiedNum,
  StyledSideMenuFCLink,
  StyledAppWidthSizeButton,
} from './styles';

const Navigator: React.FC = () => {
  const { me } = useUserContext();
  const {
    hideNavMenu,
    navMenuList,
    onHideNavChangeHandler,
    onNavMenuListChangeHandler,
  } = useAppContext();
  const [currentPage, setCurrentPage] = useState<string>('');
  const [latestMessagesLength, setLatestMessagesLength] = useState<number>(0);
  const [latestAnnouncementsLength, setLatestAnnouncementsLength] = useState<number>(0);
  const cid: string | null = localStorage.getItem('cid');
  const history = useHistory();
  const location = useLocation();
  const urlQueries = new URLSearchParams(useLocation().search);

  const onTabSelectHandler = (value: string) => {
    setCurrentPage(value);
  };

  useEffect(() => {
    const companyId: string | null = urlQueries.get('cid');
    const type = getTypeFromCookie();
    if (companyId && type === 'admin') {
      useGraphQLQuery(GET_COMPANY, {
        companyId: Number(companyId),
      }).then((dta: any) => {
        if (dta.getCompany.success) {
          if (dta.getCompany.data.companies[0].companyType !== 'admin') {
            localStorage.setItem('vmode', 'true');
            localStorage.setItem('cid', dta.getCompany.data.companies[0]._id);
            localStorage.setItem(
              'cname',
              dta.getCompany.data.companies[0].companyName,
            );
            localStorage.setItem(
              'ctype',
              dta.getCompany.data.companies[0].companyType,
            );

            onNavMenuListChangeHandler();
          }
        }
      });
    }
  }, []);

  useEffect(() => {
    onTabSelectHandler(location.pathname.split('/')[1]);
  }, [location]);

  useEffect(() => {
    if (me && me.companyId) {
      useGraphQLQuery(GET_LATEST_MESSAGES, {
        companyId: cid ? Number(cid) : me.companyId,
      }).then((dta: any) => {
        if (dta.latestMessages.success) {
          setLatestMessagesLength(dta.latestMessages.data.size);
        }
      });
      useGraphQLQuery(GET_ANNOUNCEMENTS_BY_DAYS, {
        announcementQuery: {
          companyId: cid ? Number(cid) : me.companyId,
          days: 5,
        },
      }).then((dta: any) => {
        if (dta.announcementsByDays.success) {
          setLatestAnnouncementsLength(dta.announcementsByDays.data.size);
        }
      });
    }
  }, [me, cid]);

  const onRemoveViewModeHandler = () => {
    const companyType: string | null = localStorage.getItem('ctype');
    clearStorage();
    onNavMenuListChangeHandler();

    if (companyType === 'Merchant') {
      history.replace('/merchantview');
    } else {
      history.replace('/publisherview');
    }
  };

  const sideMenuNotificationMargin = () => {
    if (latestMessagesLength > 0 || latestAnnouncementsLength > 0) {
      return 7;
    }
    return 5;
  };

  const renderBadge = (pageTitle: string, entrySize: number) => {
    if (
      (pageTitle === 'inbox' || pageTitle === 'announcements')
      && entrySize !== 0
    ) {
      if (!hideNavMenu) {
        return (
          <Badge
            width={`${entrySize.toString().length * 8 + 8}px`}
            height={toRem(16)}
            padding={toRem(4)}
            borderRadius="8px"
          >
            {entrySize}
          </Badge>
        );
      }
      return <StyledSideMenuSimplifiedNum />;
    }
    return <></>;
  };

  return (
    <>
      <StyledNavWrapper
        minWidth={hideNavMenu ? 60 : 208}
        width={hideNavMenu ? 68 : 240}
      >
        <StyledAppWidthSizeButton
          onClick={onHideNavChangeHandler}
          left={hideNavMenu ? '72%' : '92%'}
        >
          {hideNavMenu ? (
            <Icon icon="chev_right" color={colors.color2} />
          ) : (
            <Icon icon="chev_left" color={colors.color2} />
          )}
        </StyledAppWidthSizeButton>
        <StyledSideMenuWrapper>
          <StyledSideMenuRow contentPosition="center">
            <Logo hideSideMenu={hideNavMenu} />
            <StyledSideMenuNavigator onClick={() => {}}>
              <Icon
                icon="chev_left"
                marginLeft={hideNavMenu ? sideMenuNotificationMargin() : 0}
              />
              {!hideNavMenu && (
                <StyledSideMenuFCLink
                  href={process.env.REACT_APP_APP_URI}
                  target="_blank"
                >
                  <Text
                    fontSize={14}
                    fontFamily={fonts.font2}
                    color={colors.color1}
                  >
                    Back to Dashboard
                  </Text>
                </StyledSideMenuFCLink>
              )}
            </StyledSideMenuNavigator>
            {!hideNavMenu && cid && (
              <>
                <StyledSideMenuNavigator onClick={onRemoveViewModeHandler}>
                  <Icon icon="chev_left" />
                  <Text
                    fontSize={14}
                    fontFamily={fonts.font2}
                    color={colors.color1}
                  >
                    Admin view
                  </Text>
                </StyledSideMenuNavigator>
                <Threshold marginBottom={16} marginTop={16} width="168px" />
                <StyledSideMenuNavigator onClick={() => {}}>
                  <Text
                    fontSize={14}
                    fontFamily={fonts.font2}
                    color={colors.color1}
                    lineHeight={1.2}
                  >
                    {localStorage.getItem('cname')}
                  </Text>
                </StyledSideMenuNavigator>
              </>
            )}
            {navMenuList
              && navMenuList.map((page: any) => (
                <SideMenuLink
                  key={page.pageTitle}
                  to={`${page.path}${
                    localStorage.getItem('vmode')
                      ? `/viewas/${localStorage.getItem('cid')}`
                      : ''
                  }`}
                  active={page.path.split('/')[1] === currentPage}
                  onClick={() => {
                    onTabSelectHandler(page.pageTitle);
                  }}
                >
                  <StyledSideMenuLinkTitle>
                    <Icon
                      icon={page.icon}
                      width={20}
                      viewbox="0 0 18 18"
                      marginLeft={
                        hideNavMenu ? sideMenuNotificationMargin() : 0
                      }
                    />
                    {!hideNavMenu && (
                      <div style={{ paddingLeft: toRem(8) }}>
                        {page.pageTitle.charAt(0).toUpperCase()
                          + page.pageTitle.slice(1)}
                      </div>
                    )}
                  </StyledSideMenuLinkTitle>
                  {renderBadge(
                    page.pageTitle,
                    page.pageTitle === 'inbox'
                      ? latestMessagesLength
                      : latestAnnouncementsLength,
                  )}
                </SideMenuLink>
              ))}
          </StyledSideMenuRow>
          <StyledSideMenuRow position="bottom" contentPosition="center">
            <SideMenuHelpLink hideSideMenu={hideNavMenu} />
          </StyledSideMenuRow>
        </StyledSideMenuWrapper>
      </StyledNavWrapper>
    </>
  );
};
export default Navigator;

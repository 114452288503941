// import hexToRGB from 'utils/hexToRGB';

// export const colors = {
//   color1: `rgb(${hexToRGB('#F78D2D')})`,
//   color2: `rgb(${hexToRGB('#ffffff')})`,
//   color3: `rgb(${hexToRGB('#022658')})`,
//   color4: `rgb(${hexToRGB('#696969')})`,
//   color5: `rgb(${hexToRGB('#4BC7C0')})`,
//   color6: `rgb(${hexToRGB('#FF5D68')})`,
//   color7: `rgb(${hexToRGB('#92ADCB')})`,
//   color8: `rgb(${hexToRGB('#FDE8D5')})`,
//   color9: `rgb(${hexToRGB('#E36C00')})`,
//   color10: `rgb(${hexToRGB('#FCD1AB')})`,
//   color11: `rgb(${hexToRGB('#F5F5F5')})`,
//   color12: `rgb(${hexToRGB('#3B3B3B')})`,
// };

export const colors = {
  color1: '#F78D2D',
  color2: '#ffffff',
  color3: '#022658',
  color4: '#8A8A8A',
  color5: '#4BC7C0',
  color6: '#FF5D68',
  color7: '#92ADCB',
  color8: '#FDE8D5',
  color9: '#E36C00',
  color10: '#FCD1AB',
  color11: '#F5F5F5',
  color12: '#3B3B3B',
};

export const fonts = {
  font1: 'GothamPro',
  font2: 'GothamPro_Bold',
  font3: 'Lato',
  font4: 'Lato_Bold',
};

import React from 'react';
import ButtonIcon from 'components/Button/ButtonIcon';
import { colors, fonts } from 'styles/theme';
import Text from 'components/Text';
import Icon from '../Icon';
import {
  StyledToastWrapper,
  StyledToastMainTitle,
  StyledToastLeft,
  StyledToastRedirectLink,
} from './styles';

interface ToastProps {
  type: string;
  message: string;
  link?: string;
  linkTitle?: string;
  onCloseHandler(): void;
}

const Toast: React.FC<ToastProps> = ({
  type,
  message,
  link = undefined,
  linkTitle = undefined,
  onCloseHandler,
}) => (
  <StyledToastWrapper isToastOpen type={type}>
    <StyledToastLeft>
      {type !== 'error' ? (
        <Icon icon="check" viewbox="0 0 24 24" width={24} height={24} />
      ) : (
        <Icon
          icon="info"
          viewbox="0 0 16 16"
          width={24}
          height={24}
          paddingTop={2}
        />
      )}
      <StyledToastMainTitle>
        <Text fontSize={14} fontFamily={fonts.font2} color={colors.color3}>
          {message}
        </Text>
      </StyledToastMainTitle>

      {link && linkTitle && (
        <StyledToastRedirectLink to={link}>
          <Text fontSize={14} fontFamily={fonts.font2}>
            {linkTitle}
          </Text>
        </StyledToastRedirectLink>
      )}
    </StyledToastLeft>
    <ButtonIcon
      width={20}
      height={20}
      icon="close_lg"
      margin={0}
      color="black"
      onClick={onCloseHandler}
    />
  </StyledToastWrapper>
);

Toast.defaultProps = {
  link: '',
  linkTitle: '',
};

export default Toast;

import styled from 'styled-components';
import { fonts } from 'styles/theme';
import toRem from 'utils/toRem';

interface IText {
  fontFamily?: string;
  fontSize?: number;
  fontWeight?: number;
  color?: string;
  lineHeight?: number;
  textTransfrom?: string;
  textDecoration?: string;
  textUnderline?: boolean;
}

const Text = styled.div<IText>`
  display: inline;
  color: ${(props) => (props.color ? props.color : undefined)};

  font-family: ${(props) => props.fontFamily};
  font-size: ${(props) => toRem(props.fontSize!)};
  font-weight: ${(props) => props.fontWeight};
  text-transform: ${(props) => props.textTransfrom};
  text-decoration: ${(props) => props.textDecoration};
  line-height: ${(props) => props.lineHeight};
  ${(props) => (props.textUnderline ? 'text-decoration: underline;' : '')};
`;

Text.defaultProps = {
  fontFamily: fonts.font1,
  fontSize: 14,
  fontWeight: 400,
  color: undefined,
  lineHeight: 1,
  textTransfrom: undefined,
  textDecoration: undefined,
  textUnderline: false,
};

export default Text;

import styled from 'styled-components';
import toRem from 'utils/toRem';
import flexGen from 'utils/flexGen';

interface IButtonIcon {
  margin: number;
  tooltip: boolean;
}

export const StyledButtonIcon = styled.button<IButtonIcon>`
  ${flexGen()};
  background-color: transparent;
  border: none;
  font-size: ${toRem(14)};
  padding: ${toRem(2)};
  margin: ${(props) => toRem(props.margin)};
  border-radius: 4px;
  ${(props) => props.tooltip
    && `&:hover {
          background-color: #F5F5F5;
          &+div {
            opacity: 1;
          }
        }`}
`;

import { DocumentNode } from 'graphql';
import { client as apollo } from 'utils/apollo';

export const useGraphQLQuery = (query: DocumentNode, variables: any) => new Promise((resolve: any) => {
  try {
    apollo
      .query({
        fetchPolicy: 'network-only',
        query,
        context: {},
        variables,
      })
      .then((result: any) => {
        if (result.error) {
          if (result.error.graphQLErrors || result.error.clientErrors) {
            throw new Error('page');
          } else {
            throw new Error('server');
          }
        }
        resolve(result.data);
      });
  } catch (error: any) {
    if (error.message === 'page') {
      window.location.href = '/error';
    } else {
      window.location.href = '/servererror';
    }
  }
});

import auth0 from 'auth0-js';

class Auth0 {
  readonly obj: auth0.WebAuth;

  constructor() {
    this.obj = new auth0.WebAuth({
      clientID: process.env.REACT_APP_AUTH0_CLIENT_ID!,
      domain: process.env.REACT_APP_AUTH0_DOMAIN!,
      redirectUri: process.env.REACT_APP_AUTH0_REDIRECT_URI,
      audience: `https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/`,
      scope: 'read:current_user update:current_user_metadata app_metadata',
      responseType: 'token id_token',
    });
  }
}

export default new Auth0();

import React from 'react';
import Text from 'components/Text';
import { fonts } from 'styles/theme';
import toRem from 'utils/toRem';
import { useUserContext } from 'state/context/userContext';
import Icon from '../../Icon';
import { StyledSideMenuHelpWrapper, StyledSidemenuKBLink } from './styles';

interface SideMenuHelpLinkProps {
  hideSideMenu: boolean;
}

const SideMenuHelpLink: React.FC<SideMenuHelpLinkProps> = ({
  hideSideMenu,
}) => {
  const { me } = useUserContext();

  return me.userType === 'Admin' ? (
    <StyledSideMenuHelpWrapper hideSideMenu={hideSideMenu}>
      <StyledSidemenuKBLink
        href={`${process.env.REACT_APP_KNOWLEDGE_BASE_URI}`}
        target="_blank"
      >
        <Icon icon="help" width={24} height={24} viewbox="0 0 20 20" />

        {!hideSideMenu && (
          <div style={{ paddingLeft: toRem(8) }}>
            <Text fontSize={16} fontFamily={fonts.font2}>
              Help
            </Text>
          </div>
        )}
      </StyledSidemenuKBLink>
    </StyledSideMenuHelpWrapper>
  ) : (
    <></>
  );
};

export default SideMenuHelpLink;

import styled from 'styled-components';

interface NavContainerProps {
  marginWidth: number;
}

const NavContainer = styled.div<NavContainerProps>`
  width: 100%;
  height: 100%;
  /* overflow-y: hidden; */
`;

export default NavContainer;

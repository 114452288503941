import path from './path';

/* eslint-disable no-param-reassign */
const merchantTypesPermissions = [
  {
    page: path.inbox,
    permissions: [
      {
        type: 'Self',
        rn: [
          {
            status: 'Approved',
            access: true,
            readonly: false,
            pathOverwrite: '/',
          },
          {
            status: 'Pending',
            access: false,
            readonly: true,
            pathOverwrite: '/',
          },
          {
            status: 'Paused',
            access: true,
            readonly: false,
            pathOverwrite: '/',
          },
          {
            status: 'Closed',
            access: true,
            readonly: true,
            pathOverwrite: '/',
          },
          {
            status: 'Integrating',
            access: true,
            readonly: true,
            pathOverwrite: '/',
          },
          {
            status: 'LivePending',
            access: true,
            readonly: true,
            pathOverwrite: '/',
          },
        ],
      },
      {
        type: 'Managed',
        rn: [
          {
            status: 'Approved',
            access: true,
            readonly: false,
            pathOverwrite: '/',
          },
          {
            status: 'Pending',
            access: false,
            readonly: false,
            pathOverwrite: '/',
          },
          {
            status: 'Paused',
            access: true,
            readonly: false,
            pathOverwrite: '/',
          },
          {
            status: 'Closed',
            access: true,
            readonly: true,
            pathOverwrite: '/',
          },
          {
            status: 'Integrating',
            access: true,
            readonly: true,
            pathOverwrite: '/',
          },
          {
            status: 'LivePending',
            access: true,
            readonly: true,
            pathOverwrite: '/',
          },
        ],
      },
      {
        type: 'Results',
        rn: [
          {
            status: 'Approved',
            access: true,
            readonly: false,
            pathOverwrite: '/',
          },
          {
            status: 'Pending',
            access: false,
            readonly: false,
            pathOverwrite: '/',
          },
          {
            status: 'Paused',
            access: true,
            readonly: false,
            pathOverwrite: '/',
          },
          {
            status: 'Closed',
            access: false,
            readonly: false,
            pathOverwrite: '/',
          },
          {
            status: 'Integrating',
            access: true,
            readonly: true,
            pathOverwrite: '/',
          },
          {
            status: 'LivePending',
            access: true,
            readonly: true,
            pathOverwrite: '/',
          },
        ],
      },
    ],
  },
  {
    page: path.newMessage,
    permissions: [
      {
        type: 'Self',
        rn: [
          {
            status: 'Approved',
            access: true,
            readonly: false,
            pathOverwrite: path.inbox,
          },
          {
            status: 'Pending',
            access: false,
            readonly: true,
            pathOverwrite: path.inbox,
          },
          {
            status: 'Paused',
            access: true,
            readonly: false,
            pathOverwrite: path.inbox,
          },
          {
            status: 'Closed',
            access: false,
            readonly: true,
            pathOverwrite: path.inbox,
          },
          {
            status: 'Integrating',
            access: false,
            readonly: true,
            pathOverwrite: path.inbox,
          },
          {
            status: 'LivePending',
            access: false,
            readonly: true,
            pathOverwrite: path.inbox,
          },
        ],
      },
      {
        type: 'Managed',
        rn: [
          {
            status: 'Approved',
            access: true,
            readonly: false,
            pathOverwrite: path.inbox,
          },
          {
            status: 'Pending',
            access: false,
            readonly: true,
            pathOverwrite: path.inbox,
          },
          {
            status: 'Paused',
            access: true,
            readonly: false,
            pathOverwrite: path.inbox,
          },
          {
            status: 'Closed',
            access: false,
            readonly: true,
            pathOverwrite: path.inbox,
          },
          {
            status: 'Integrating',
            access: false,
            readonly: true,
            pathOverwrite: path.inbox,
          },
          {
            status: 'LivePending',
            access: false,
            readonly: true,
            pathOverwrite: path.inbox,
          },
        ],
      },
      {
        type: 'Results',
        rn: [
          {
            status: 'Approved',
            access: true,
            readonly: false,
            pathOverwrite: path.inbox,
          },
          {
            status: 'Pending',
            access: false,
            readonly: true,
            pathOverwrite: path.inbox,
          },
          {
            status: 'Paused',
            access: true,
            readonly: false,
            pathOverwrite: path.inbox,
          },
          {
            status: 'Closed',
            access: false,
            readonly: true,
            pathOverwrite: path.inbox,
          },
          {
            status: 'Integrating',
            access: false,
            readonly: true,
            pathOverwrite: path.inbox,
          },
          {
            status: 'LivePending',
            access: false,
            readonly: true,
            pathOverwrite: path.inbox,
          },
        ],
      },
    ],
  },
  {
    page: path.announcements,
    permissions: [
      {
        type: 'Self',
        rn: [
          {
            status: 'Approved',
            access: true,
            readonly: false,
            pathOverwrite: path.inbox,
          },
          {
            status: 'Pending',
            access: false,
            readonly: true,
            pathOverwrite: path.inbox,
          },
          {
            status: 'Paused',
            access: true,
            readonly: false,
            pathOverwrite: path.inbox,
          },
          {
            status: 'Closed',
            access: false,
            readonly: true,
            pathOverwrite: path.inbox,
          },
          {
            status: 'Integrating',
            access: true,
            readonly: true,
            pathOverwrite: path.inbox,
          },
          {
            status: 'LivePending',
            access: true,
            readonly: true,
            pathOverwrite: path.inbox,
          },
        ],
      },
      {
        type: 'Managed',
        rn: [
          {
            status: 'Approved',
            access: true,
            readonly: false,
            pathOverwrite: path.inbox,
          },
          {
            status: 'Pending',
            access: false,
            readonly: true,
            pathOverwrite: path.inbox,
          },
          {
            status: 'Paused',
            access: true,
            readonly: false,
            pathOverwrite: path.inbox,
          },
          {
            status: 'Closed',
            access: false,
            readonly: true,
            pathOverwrite: path.inbox,
          },
          {
            status: 'Integrating',
            access: true,
            readonly: true,
            pathOverwrite: path.inbox,
          },
          {
            status: 'LivePending',
            access: true,
            readonly: true,
            pathOverwrite: path.inbox,
          },
        ],
      },
      {
        type: 'Results',
        rn: [
          {
            status: 'Approved',
            access: true,
            readonly: false,
            pathOverwrite: path.inbox,
          },
          {
            status: 'Pending',
            access: false,
            readonly: true,
            pathOverwrite: path.inbox,
          },
          {
            status: 'Paused',
            access: true,
            readonly: false,
            pathOverwrite: path.inbox,
          },
          {
            status: 'Closed',
            access: false,
            readonly: true,
            pathOverwrite: path.inbox,
          },
          {
            status: 'Integrating',
            access: true,
            readonly: true,
            pathOverwrite: path.inbox,
          },
          {
            status: 'LivePending',
            access: true,
            readonly: true,
            pathOverwrite: path.inbox,
          },
        ],
      },
    ],
  },
  {
    page: path.announcement,
    permissions: [
      {
        type: 'Self',
        rn: [
          {
            status: 'Approved',
            access: true,
            readonly: false,
            pathOverwrite: path.inbox,
          },
          {
            status: 'Pending',
            access: false,
            readonly: true,
            pathOverwrite: path.inbox,
          },
          {
            status: 'Paused',
            access: true,
            readonly: false,
            pathOverwrite: path.inbox,
          },
          {
            status: 'Closed',
            access: false,
            readonly: true,
            pathOverwrite: path.inbox,
          },
          {
            status: 'Integrating',
            access: true,
            readonly: true,
            pathOverwrite: path.inbox,
          },
          {
            status: 'LivePending',
            access: true,
            readonly: true,
            pathOverwrite: path.inbox,
          },
        ],
      },
      {
        type: 'Managed',
        rn: [
          {
            status: 'Approved',
            access: true,
            readonly: false,
            pathOverwrite: path.inbox,
          },
          {
            status: 'Pending',
            access: false,
            readonly: true,
            pathOverwrite: path.inbox,
          },
          {
            status: 'Paused',
            access: true,
            readonly: false,
            pathOverwrite: path.inbox,
          },
          {
            status: 'Closed',
            access: false,
            readonly: true,
            pathOverwrite: path.inbox,
          },
          {
            status: 'Integrating',
            access: true,
            readonly: true,
            pathOverwrite: path.inbox,
          },
          {
            status: 'LivePending',
            access: true,
            readonly: true,
            pathOverwrite: path.inbox,
          },
        ],
      },
      {
        type: 'Results',
        rn: [
          {
            status: 'Approved',
            access: true,
            readonly: false,
            pathOverwrite: path.inbox,
          },
          {
            status: 'Pending',
            access: false,
            readonly: true,
            pathOverwrite: path.inbox,
          },
          {
            status: 'Paused',
            access: true,
            readonly: false,
            pathOverwrite: path.inbox,
          },
          {
            status: 'Closed',
            access: false,
            readonly: true,
            pathOverwrite: path.inbox,
          },
          {
            status: 'Integrating',
            access: true,
            readonly: true,
            pathOverwrite: path.inbox,
          },
          {
            status: 'LivePending',
            access: true,
            readonly: true,
            pathOverwrite: path.inbox,
          },
        ],
      },
    ],
  },
  {
    page: path.newAnnouncement,
    permissions: [
      {
        type: 'Self',
        rn: [
          {
            status: 'Approved',
            access: true,
            readonly: false,
            pathOverwrite: path.inbox,
          },
          {
            status: 'Pending',
            access: false,
            readonly: true,
            pathOverwrite: path.inbox,
          },
          {
            status: 'Paused',
            access: true,
            readonly: false,
            pathOverwrite: path.inbox,
          },
          {
            status: 'Closed',
            access: false,
            readonly: true,
            pathOverwrite: path.inbox,
          },
          {
            status: 'Integrating',
            access: true,
            readonly: true,
            pathOverwrite: path.inbox,
          },
          {
            status: 'LivePending',
            access: true,
            readonly: true,
            pathOverwrite: path.inbox,
          },
        ],
      },
      {
        type: 'Managed',
        rn: [
          {
            status: 'Approved',
            access: true,
            readonly: false,
            pathOverwrite: path.inbox,
          },
          {
            status: 'Pending',
            access: false,
            readonly: true,
            pathOverwrite: path.inbox,
          },
          {
            status: 'Paused',
            access: true,
            readonly: false,
            pathOverwrite: path.inbox,
          },
          {
            status: 'Closed',
            access: false,
            readonly: true,
            pathOverwrite: path.inbox,
          },
          {
            status: 'Integrating',
            access: true,
            readonly: true,
            pathOverwrite: path.inbox,
          },
          {
            status: 'LivePending',
            access: true,
            readonly: true,
            pathOverwrite: path.inbox,
          },
        ],
      },
      {
        type: 'Results',
        rn: [
          {
            status: 'Approved',
            access: true,
            readonly: false,
            pathOverwrite: path.inbox,
          },
          {
            status: 'Pending',
            access: false,
            readonly: true,
            pathOverwrite: path.inbox,
          },
          {
            status: 'Paused',
            access: true,
            readonly: false,
            pathOverwrite: path.inbox,
          },
          {
            status: 'Closed',
            access: false,
            readonly: true,
            pathOverwrite: path.inbox,
          },
          {
            status: 'Integrating',
            access: true,
            readonly: true,
            pathOverwrite: path.inbox,
          },
          {
            status: 'LivePending',
            access: true,
            readonly: true,
            pathOverwrite: path.inbox,
          },
        ],
      },
    ],
  },
  {
    page: path.archive,
    permissions: [
      {
        type: 'Self',
        rn: [
          {
            status: 'Approved',
            access: true,
            readonly: false,
            pathOverwrite: '/',
          },
          {
            status: 'Pending',
            access: false,
            readonly: true,
            pathOverwrite: '/',
          },
          {
            status: 'Paused',
            access: true,
            readonly: false,
            pathOverwrite: '/',
          },
          {
            status: 'Closed',
            access: true,
            readonly: true,
            pathOverwrite: '/',
          },
          {
            status: 'Integrating',
            access: true,
            readonly: true,
            pathOverwrite: '/',
          },
          {
            status: 'LivePending',
            access: true,
            readonly: true,
            pathOverwrite: '/',
          },
        ],
      },
      {
        type: 'Managed',
        rn: [
          {
            status: 'Approved',
            access: true,
            readonly: false,
            pathOverwrite: '/',
          },
          {
            status: 'Pending',
            access: false,
            readonly: false,
            pathOverwrite: '/',
          },
          {
            status: 'Paused',
            access: true,
            readonly: false,
            pathOverwrite: '/',
          },
          {
            status: 'Closed',
            access: true,
            readonly: true,
            pathOverwrite: '/',
          },
          {
            status: 'Integrating',
            access: true,
            readonly: true,
            pathOverwrite: '/',
          },
          {
            status: 'LivePending',
            access: true,
            readonly: true,
            pathOverwrite: '/',
          },
        ],
      },
      {
        type: 'Results',
        rn: [
          {
            status: 'Approved',
            access: true,
            readonly: false,
            pathOverwrite: '/',
          },
          {
            status: 'Pending',
            access: false,
            readonly: false,
            pathOverwrite: '/',
          },
          {
            status: 'Paused',
            access: true,
            readonly: false,
            pathOverwrite: '/',
          },
          {
            status: 'Closed',
            access: false,
            readonly: false,
            pathOverwrite: '/',
          },
          {
            status: 'Integrating',
            access: true,
            readonly: false,
            pathOverwrite: '/',
          },
          {
            status: 'LivePending',
            access: true,
            readonly: false,
            pathOverwrite: '/',
          },
        ],
      },
    ],
  },
  {
    page: path.message,
    permissions: [
      {
        type: 'Self',
        rn: [
          {
            status: 'Approved',
            access: true,
            readonly: false,
            pathOverwrite: '/',
          },
          {
            status: 'Pending',
            access: false,
            readonly: true,
            pathOverwrite: '/',
          },
          {
            status: 'Paused',
            access: true,
            readonly: false,
            pathOverwrite: '/',
          },
          {
            status: 'Closed',
            access: true,
            readonly: true,
            pathOverwrite: '/',
          },
          {
            status: 'Integrating',
            access: true,
            readonly: true,
            pathOverwrite: '/',
          },
          {
            status: 'LivePending',
            access: true,
            readonly: true,
            pathOverwrite: '/',
          },
        ],
      },
      {
        type: 'Managed',
        rn: [
          {
            status: 'Approved',
            access: true,
            readonly: false,
            pathOverwrite: '/',
          },
          {
            status: 'Pending',
            access: false,
            readonly: false,
            pathOverwrite: '/',
          },
          {
            status: 'Paused',
            access: true,
            readonly: false,
            pathOverwrite: '/',
          },
          {
            status: 'Closed',
            access: true,
            readonly: true,
            pathOverwrite: '/',
          },
          {
            status: 'Integrating',
            access: true,
            readonly: true,
            pathOverwrite: '/',
          },
          {
            status: 'LivePending',
            access: true,
            readonly: true,
            pathOverwrite: '/',
          },
        ],
      },
      {
        type: 'Results',
        rn: [
          {
            status: 'Approved',
            access: true,
            readonly: false,
            pathOverwrite: '/',
          },
          {
            status: 'Pending',
            access: false,
            readonly: false,
            pathOverwrite: '/',
          },
          {
            status: 'Paused',
            access: true,
            readonly: false,
            pathOverwrite: '/',
          },
          {
            status: 'Closed',
            access: false,
            readonly: false,
            pathOverwrite: '/',
          },
          {
            status: 'Integrating',
            access: true,
            readonly: false,
            pathOverwrite: '/',
          },
          {
            status: 'LivePending',
            access: true,
            readonly: false,
            pathOverwrite: '/',
          },
        ],
      },
    ],
  },
  {
    page: path.templates,
    permissions: [
      {
        type: 'Self',
        rn: [
          {
            status: 'Approved',
            access: true,
            readonly: false,
            pathOverwrite: path.inbox,
          },
          {
            status: 'Pending',
            access: false,
            readonly: true,
            pathOverwrite: path.inbox,
          },
          {
            status: 'Paused',
            access: true,
            readonly: false,
            pathOverwrite: path.inbox,
          },
          {
            status: 'Closed',
            access: false,
            readonly: true,
            pathOverwrite: path.inbox,
          },
          {
            status: 'Integrating',
            access: true,
            readonly: true,
            pathOverwrite: path.inbox,
          },
          {
            status: 'LivePending',
            access: true,
            readonly: true,
            pathOverwrite: path.inbox,
          },
        ],
      },
      {
        type: 'Managed',
        rn: [
          {
            status: 'Approved',
            access: true,
            readonly: false,
            pathOverwrite: path.inbox,
          },
          {
            status: 'Pending',
            access: false,
            readonly: true,
            pathOverwrite: path.inbox,
          },
          {
            status: 'Paused',
            access: true,
            readonly: false,
            pathOverwrite: path.inbox,
          },
          {
            status: 'Closed',
            access: false,
            readonly: true,
            pathOverwrite: path.inbox,
          },
          {
            status: 'Integrating',
            access: true,
            readonly: true,
            pathOverwrite: path.inbox,
          },
          {
            status: 'LivePending',
            access: true,
            readonly: true,
            pathOverwrite: path.inbox,
          },
        ],
      },
      {
        type: 'Results',
        rn: [
          {
            status: 'Approved',
            access: true,
            readonly: false,
            pathOverwrite: path.inbox,
          },
          {
            status: 'Pending',
            access: false,
            readonly: true,
            pathOverwrite: path.inbox,
          },
          {
            status: 'Paused',
            access: true,
            readonly: false,
            pathOverwrite: path.inbox,
          },
          {
            status: 'Closed',
            access: false,
            readonly: true,
            pathOverwrite: path.inbox,
          },
          {
            status: 'Integrating',
            access: true,
            readonly: true,
            pathOverwrite: path.inbox,
          },
          {
            status: 'LivePending',
            access: true,
            readonly: true,
            pathOverwrite: path.inbox,
          },
        ],
      },
    ],
  },
  {
    page: path.template,
    permissions: [
      {
        type: 'Self',
        rn: [
          {
            status: 'Approved',
            access: true,
            readonly: false,
            pathOverwrite: path.inbox,
          },
          {
            status: 'Pending',
            access: false,
            readonly: true,
            pathOverwrite: path.inbox,
          },
          {
            status: 'Paused',
            access: true,
            readonly: false,
            pathOverwrite: path.inbox,
          },
          {
            status: 'Closed',
            access: false,
            readonly: true,
            pathOverwrite: path.inbox,
          },
          {
            status: 'Integrating',
            access: true,
            readonly: true,
            pathOverwrite: path.inbox,
          },
          {
            status: 'LivePending',
            access: true,
            readonly: true,
            pathOverwrite: path.inbox,
          },
        ],
      },
      {
        type: 'Managed',
        rn: [
          {
            status: 'Approved',
            access: true,
            readonly: false,
            pathOverwrite: path.inbox,
          },
          {
            status: 'Pending',
            access: false,
            readonly: true,
            pathOverwrite: path.inbox,
          },
          {
            status: 'Paused',
            access: true,
            readonly: false,
            pathOverwrite: path.inbox,
          },
          {
            status: 'Closed',
            access: false,
            readonly: true,
            pathOverwrite: path.inbox,
          },
          {
            status: 'Integrating',
            access: true,
            readonly: true,
            pathOverwrite: path.inbox,
          },
          {
            status: 'LivePending',
            access: true,
            readonly: true,
            pathOverwrite: path.inbox,
          },
        ],
      },
      {
        type: 'Results',
        rn: [
          {
            status: 'Approved',
            access: true,
            readonly: false,
            pathOverwrite: path.inbox,
          },
          {
            status: 'Pending',
            access: false,
            readonly: true,
            pathOverwrite: path.inbox,
          },
          {
            status: 'Paused',
            access: true,
            readonly: false,
            pathOverwrite: path.inbox,
          },
          {
            status: 'Closed',
            access: false,
            readonly: true,
            pathOverwrite: path.inbox,
          },
          {
            status: 'Integrating',
            access: true,
            readonly: true,
            pathOverwrite: path.inbox,
          },
          {
            status: 'LivePending',
            access: true,
            readonly: true,
            pathOverwrite: path.inbox,
          },
        ],
      },
    ],
  },
  {
    page: path.newTemplate,
    permissions: [
      {
        type: 'Self',
        rn: [
          {
            status: 'Approved',
            access: true,
            readonly: false,
            pathOverwrite: path.inbox,
          },
          {
            status: 'Pending',
            access: false,
            readonly: true,
            pathOverwrite: path.inbox,
          },
          {
            status: 'Paused',
            access: true,
            readonly: false,
            pathOverwrite: path.inbox,
          },
          {
            status: 'Closed',
            access: false,
            readonly: true,
            pathOverwrite: path.inbox,
          },
          {
            status: 'Integrating',
            access: true,
            readonly: true,
            pathOverwrite: path.inbox,
          },
          {
            status: 'LivePending',
            access: true,
            readonly: true,
            pathOverwrite: path.inbox,
          },
        ],
      },
      {
        type: 'Managed',
        rn: [
          {
            status: 'Approved',
            access: true,
            readonly: false,
            pathOverwrite: path.inbox,
          },
          {
            status: 'Pending',
            access: false,
            readonly: true,
            pathOverwrite: path.inbox,
          },
          {
            status: 'Paused',
            access: true,
            readonly: false,
            pathOverwrite: path.inbox,
          },
          {
            status: 'Closed',
            access: false,
            readonly: true,
            pathOverwrite: path.inbox,
          },
          {
            status: 'Integrating',
            access: true,
            readonly: true,
            pathOverwrite: path.inbox,
          },
          {
            status: 'LivePending',
            access: true,
            readonly: true,
            pathOverwrite: path.inbox,
          },
        ],
      },
      {
        type: 'Results',
        rn: [
          {
            status: 'Approved',
            access: true,
            readonly: false,
            pathOverwrite: path.inbox,
          },
          {
            status: 'Pending',
            access: false,
            readonly: true,
            pathOverwrite: path.inbox,
          },
          {
            status: 'Paused',
            access: true,
            readonly: false,
            pathOverwrite: path.inbox,
          },
          {
            status: 'Closed',
            access: false,
            readonly: true,
            pathOverwrite: path.inbox,
          },
          {
            status: 'Integrating',
            access: true,
            readonly: true,
            pathOverwrite: path.inbox,
          },
          {
            status: 'LivePending',
            access: true,
            readonly: true,
            pathOverwrite: path.inbox,
          },
        ],
      },
    ],
  },
];

export type userObject = {
  userType: string,
  company: {
    accountStatus: string,
    merchantType: string,
  }
}

export const checkMerchantTypePermissions = (type: string, status: string, page: string): any => {
  const findPage = merchantTypesPermissions.find((permission) => permission.page === page);

  if (!findPage) return { access: false };

  const findPermission = findPage.permissions.find((permission) => permission.type === type);

  if (!findPermission) return { access: false };

  const findStatus = findPermission.rn.find((permission) => permission.status === status);

  if (!findStatus) return { access: false };

  return findStatus;
};

// eslint-disable-next-line no-unused-vars
export const setMerchantTypeReadonly = async (user: userObject, page: string, viewOnly: boolean): Promise<boolean> => new Promise((resolve) => {
  if (user?.userType === 'Merchant' && !viewOnly) {
    const checkAcces = checkMerchantTypePermissions(user.company.merchantType, user.company.accountStatus, page);
    resolve(checkAcces.readonly);
  }

  resolve(viewOnly);
});

export default {};

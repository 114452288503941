import { gql } from '@apollo/client';

export const GET_ANNOUNCEMENTS_BY_DAYS = gql`
  query announcementsByDays($announcementQuery: AnnouncementQuery) {
    announcementsByDays(announcementQuery: $announcementQuery) {
      success
      status
      description
      data {
        size
      }
    }
  }
`;

import styled from 'styled-components';
import { colors } from 'styles/theme';
import flexGen from 'utils/flexGen';
import toRem from 'utils/toRem';

export const StyledSpinner = styled.div`
  ${flexGen('center', 'center')}
  width: 100%;
  height: 100%;
  background-color: ${colors.color2};
  /* top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  background: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center; */
`;

export const StyledSpinnerWrapper = styled.div`
  height: ${toRem(80)};
  width: ${toRem(80)};
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`;

export const StyledSpinnerDot1 = styled.div`
  ${flexGen('center', 'center')}
  height: ${toRem(40)};
  width: ${toRem(40)};

  span {
    display: block;
    background-color: ${colors.color1};
    border-radius: 100%;
    animation: changeSize 0.4s linear infinite alternate;
  }

  @keyframes changeSize {
    0% {
      height: 1px;
      width: 1px;
    }
    30%,
    100% {
      height: 22px;
      width: 22px;
    }
  }
`;

export const StyledSpinnerDot2 = styled.div`
  ${flexGen('center', 'center')}
  height: ${toRem(40)};
  width: ${toRem(40)};

  span {
    display: block;
    background-color: ${colors.color1};
    border-radius: 100%;
    animation: changeSize 0.4s 0.3s linear infinite alternate;
  }

  @keyframes changeSize {
    0% {
      height: 1px;
      width: 1px;
    }
    30%,
    100% {
      height: 22px;
      width: 22px;
    }
  }
`;

export const StyledSpinnerDot3 = styled.div`
  ${flexGen('center', 'center')}
  height: ${toRem(40)};
  width: ${toRem(40)};

  span {
    display: block;
    background-color: ${colors.color1};
    border-radius: 100%;
    animation: changeSize 0.4s 0.6s linear infinite alternate;
  }

  @keyframes changeSize {
    0% {
      height: 1px;
      width: 1px;
    }
    30%,
    100% {
      height: 22px;
      width: 22px;
    }
  }
`;

import styled from 'styled-components';
import { colors } from 'styles/theme';
import flexGen from 'utils/flexGen';
import toRem from 'utils/toRem';

interface IStyledBadgeWrapper {
  width: string;
  height: string;
  padding: string;
  borderRadius: string;
}

export const StyledBadgeWrapper = styled.div<IStyledBadgeWrapper>`
  ${flexGen('center', 'center')}
  background-color: ${colors.color1};
  color: ${colors.color2};
  border-radius: ${(props) => props.borderRadius};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  padding: ${(props) => props.padding};
  font-size: ${toRem(12)};
`;

import React, { createContext, useContext, useReducer } from 'react';
import { ApolloError, useLazyQuery } from '@apollo/client';
import { GET_USER } from 'graphql/queries/GET_USER';
import { useHistory } from 'react-router';
import reducer from '../reducer/userReducer';
// import { removeSession } from 'styles/utils/auth';

const ActionType: any = {
  GET_ME: 'GET_ME',
};

interface IMe {
  me: {
    _id: string;
    accessToken: string;
    userType: string;
    companyName: string;
    companyId: string;
    firstName: string;
    lastName: string;
    status: string;
  };
}

const initialState: IMe = {
  me: {
    _id: '',
    accessToken: '',
    userType: '',
    companyId: '',
    companyName: '',
    firstName: '',
    lastName: '',
    status: '',
  },
};

const UserContext = createContext<any>([]);

export const UserProvider: React.FC<React.ReactNode> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const history = useHistory();

  const [getUser, { loading }] = useLazyQuery(GET_USER, {
    onError: ({ graphQLErrors, clientErrors }: ApolloError) => {
      if (graphQLErrors || clientErrors) {
        // Covers graphql type errors and client side errors
        history.push('/error');
      } else {
        // Covers network errors
        history.push('/servererror');
      }
    },
    onCompleted: (dta: any) => {
      if (dta.getUser.success) {
        localStorage.setItem('me', JSON.stringify(dta.getUser.data.users[0]));

        dispatch({
          type: ActionType.GET_ME,
          payload: dta.getUser.data.users[0],
        });
      }
    },
  });
  // currently token is equal to auth0Id for dev purpose
  const authenticate = () => {
    const me: string | null = localStorage.getItem('me');

    // 1. First login
    // 2. Cache cleared
    // 3. Login with different an user account
    if (me && JSON.parse(me) !== null) {
      dispatch({
        type: ActionType.GET_ME,
        payload: JSON.parse(me),
      });
    } else {
      getUser({
        context: {},
      });
    }
  };

  return (
    <UserContext.Provider
      value={{
        ...state,
        authenticate,
        loading,
        dispatch,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => useContext(UserContext);

import { gql } from '@apollo/client';

export const GET_TAGS = gql`
  query getTags {
    getTags {
      success
      status
      description
      data {
        tags
      }
    }
  }
`;

import styled from 'styled-components';
import { colors } from 'styles/theme';
import flexGen from 'utils/flexGen';
import toRem from 'utils/toRem';

interface IStyledAppWidthSizeButton {
  left: string;
}

export const StyledAppWrapper = styled.div`
  ${flexGen()}
  position: relative;
`;

export const StyledAppWidthSizeButton = styled.button<IStyledAppWidthSizeButton>`
  position: absolute;
  border: none;
  width: 30px;
  height: 30px;
  top: 60px;
  border-radius: 50%;
  background-color: ${colors.color3};
  left: ${(props) => props.left};
  z-index: 1000;

  svg {
    fill: ${colors.color2};
  }
`;

interface ISideMenu {
  position?: string;
  contentPosition?: string;
}

interface IStyledSideMenuWrapper {
  position?: string;
}

interface IStyledNavWrapper {
  minWidth?: number;
  width: number;
}

export const StyledNavWrapper = styled.div<IStyledNavWrapper>`
  position: relative;
  width: ${(props) => toRem(props.width)};
  min-width: ${(props) => props.minWidth}px;
`;

export const StyledSideMenuWrapper = styled.nav<IStyledSideMenuWrapper>`
  position: absolute;
  height: 100vh;
  background-color: ${colors.color3};
  ${flexGen('flex-start', 'flex-start', 'row', 'wrap')};
`;

export const StyledSideMenuNavigator = styled.div`
  ${flexGen('flex-start', 'center', 'row')}
  width: 100%;
  background-color: transparent;
  padding: ${toRem(16)};
  cursor: pointer;
  svg {
    fill: ${colors.color1};
  }
`;

export const StyledSideMenuRow = styled.div<ISideMenu>`
  width: 100%;
  align-self: ${(props) => (props.position === 'top' ? 'flex-start' : 'flex-end')};

  ${(props) => flexGen(props.contentPosition, 'center', 'column', 'wrap')}
`;

export const StyledSideMenuCounter = styled.div`
  ${flexGen('center', 'center')}
  background-color: ${colors.color1};
  color: ${colors.color2};
  border-radius: 100%;
  width: 16px;
  height: 16px;
  position: absolute;
  right: ${toRem(16)};
  font-size: ${toRem(12)};
`;

export const StyledSideMenuSimplifiedNum = styled.div`
  width: ${toRem(8)};
  height: ${toRem(8)};
  margin-left: ${toRem(6)};
  border-radius: 50%;
  background-color: ${colors.color1};
`;

export const StyledSideMenuFCLink = styled.a`
  :hover,
  :active,
  :focus {
    outline-style: none;
  }
`;

StyledSideMenuRow.defaultProps = {
  position: 'top',
  contentPosition: 'flex-start',
};

import { gql } from '@apollo/client';

export const GET_COMPANY = gql`
  query getCompany($companyId: Float!) {
    getCompany(companyId: $companyId) {
      success
      status
      description
      data {
        size
        companies {
          _id
          companyName
          companyType
          mutedMerchants
        }
      }
    }
  }
`;

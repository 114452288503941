/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { checkMerchantTypePermissions, userObject } from '../../utils/merchantTypesPermissions';

type Props = {
  children: React.ReactNode,
  user: userObject,
  path: string,
}

const MerchantCheck: React.FC<Props> = ({ children, user, path }) => {
  const [show, setShow] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (user?.userType === 'Merchant') {
      const { company } = user;

      const checkAccess = checkMerchantTypePermissions(company.merchantType, company.accountStatus, path);

      if (!checkAccess.access) {
        history.replace(checkAccess.pathOverwrite);
      } else {
        setShow(true);
      }
    } else {
      setShow(true);
    }
  }, [user.userType]);

  return (
    <>
      {show && children}
    </>
  );
};

export default MerchantCheck;

const path = {
  inbox: '/inbox',
  newMessage: '/newmessage',
  announcements: '/announcements',
  announcement: '/announcement',
  newAnnouncement: '/newannouncement',
  archive: '/archive',
  message: '/message',
  templates: '/templates',
  template: '/template',
  newTemplate: '/newtemplate',
};

export default path;

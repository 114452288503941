import React from 'react';
import toRem from 'utils/toRem';
import { StyledBadgeWrapper } from './styles';

interface BadgeProps {
  width?: string;
  height: string;
  padding?: string;
  borderRadius: string;
  children: React.ReactNode;
}

const Badge: React.FC<BadgeProps> = ({
  width = toRem(16),
  height,
  padding = '0',
  borderRadius,
  children,
}) => (
  <StyledBadgeWrapper
    width={width}
    padding={padding}
    height={height}
    borderRadius={borderRadius}
  >
    {children}
  </StyledBadgeWrapper>
);

Badge.defaultProps = {
  width: toRem(16),
  padding: '0',
};

export default Badge;

import React from 'react';
import iconList from './iconList';
import { StyledIconWrapper } from './styles';

interface IconProps {
  icon: string;
  color?: string;
  height?: number;
  width?: number;
  viewbox?: string;
  paddingRight?: number;
  paddingLeft?: number;
  paddingTop?: number;
  paddingBottom?: number;
  marginRight?: number;
  marginLeft?: number;
  marginTop?: number;
  marginBottom?: number;
  tooltip?: boolean;
}

const Icon: React.FC<IconProps> = ({
  icon,
  color,
  height,
  width,
  viewbox,
  paddingRight = 0,
  paddingLeft = 0,
  paddingTop = 0,
  paddingBottom = 0,
  marginRight = 0,
  marginLeft = 0,
  marginBottom = 0,
  marginTop = 0,
  tooltip = false,
}) => (
  <StyledIconWrapper
    paddingRight={paddingRight}
    paddingLeft={paddingLeft}
    paddingTop={paddingTop}
    paddingBottom={paddingBottom}
    marginRight={marginRight}
    marginLeft={marginLeft}
    marginBottom={marginBottom}
    marginTop={marginTop}
    tooltip={tooltip}
  >
    <svg
      fill={color}
      width={width}
      height={height}
      viewBox={viewbox}
      dangerouslySetInnerHTML={{ __html: iconList[icon] }}
    />
  </StyledIconWrapper>
);

Icon.defaultProps = {
  height: 20,
  width: 20,
  color: '',
  viewbox: '0 0 20 20',
  paddingBottom: 0,
  paddingLeft: 0,
  paddingRight: 0,
  paddingTop: 0,
  marginRight: 0,
  marginLeft: 0,
  marginBottom: 0,
  marginTop: 0,
  tooltip: false,
};

export default Icon;

import React, { useEffect, useState } from 'react';
import {
  useHistory, Route, RouteProps, useLocation,
} from 'react-router';
import Spinner from 'components/Spinner';
import { useUserContext } from 'state/context/userContext';
import { clearStorage } from 'utils/clearStorage';
import { getTypeFromCookie } from 'utils/getTypeFromCookie';

interface ProtectedRouteProps extends RouteProps {
  exact: boolean;
  path: string[] | string;
  children: React.ReactNode;
}

// 1. Check whether token is expired
// - if the token is expired, it redirects to fintel login page
// 2. Check mirror data is saved in local storage
// - if one of three information (cid, cname, ctype) is removed with reasons, it will be redirected to main page
// 3. Check me data from local storage
// - if an user clears cache storage, it redirects to fintel login page
// 4. Check whether an user is admin when url is attached with /viewas
// - if an user is admin, it goes to /viewas url
// - if an user is NOT admin, it redirects to pathname without /viewas
const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  exact,
  path,
  children,
}: any) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { authenticate } = useUserContext();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const vmode: string | null = localStorage.getItem('vmode');
    const cid: string | null = localStorage.getItem('cid');
    const cname: string | null = localStorage.getItem('cname');
    const ctype: string | null = localStorage.getItem('ctype');
    const paths: string[] = location.pathname.split('/');

    // Only allowed for Admin, Fintel Connect staffs
    if (
      paths[1] === ('perspective' || 'merchantview' || 'publisherview')
      || paths[2] === 'viewas'
    ) {
      if (getTypeFromCookie() !== 'admin') {
        clearStorage();
        history.replace('/inbox');
      }

      // 2. If someone removes one of three data from local storage in viewas mode, it will be redirected to main page
      if (
        paths[2] === 'viewas'
        && vmode
        && (cid === null || cname === null || ctype === null)
      ) {
        clearStorage();
        history.replace('/inbox');
      }
    }

    // 3. Check whether me and token data exist on local storage
    if (document.cookie) {
      authenticate();

      setIsLoading(false);
    } else {
      window.location.href = process.env.REACT_APP_APP_URI!;
    }
  }, []);
  if (isLoading) {
    return <Spinner />;
  }
  return <Route exact={exact} path={path}>{children}</Route>;
};
export default ProtectedRoute;

interface IsideMenuPageList {
  path: string;
  pageTitle: string;
  icon: string;
}

const publisherSideMenuPageList: Array<IsideMenuPageList> = [
  {
    path: '/inbox',
    pageTitle: 'inbox',
    icon: 'inbox',
  },
  {
    path: '/announcements',
    pageTitle: 'announcements',
    icon: 'globe',
  },
  {
    path: '/archive',
    pageTitle: 'archive',
    icon: 'archive',
  },
  {
    path: '/contact-list',
    pageTitle: 'Contact List',
    icon: 'list',
  },
];

const merchantSideMenuPageList: Array<IsideMenuPageList> = [
  {
    path: '/inbox',
    pageTitle: 'inbox',
    icon: 'inbox',
  },
  {
    path: '/announcements',
    pageTitle: 'announcements',
    icon: 'globe',
  },
  {
    path: '/archive',
    pageTitle: 'archive',
    icon: 'archive',
  },
  {
    path: '/templates',
    pageTitle: 'templates',
    icon: 'template',
  },
];

const adminSideMenuPageList: Array<IsideMenuPageList> = [
  {
    path: '/inbox',
    pageTitle: 'inbox',
    icon: 'inbox',
  },
  {
    path: '/announcements',
    pageTitle: 'announcements',
    icon: 'globe',
  },
  {
    path: '/archive',
    pageTitle: 'archive',
    icon: 'archive',
  },
  {
    path: '/templates',
    pageTitle: 'templates',
    icon: 'template',
  },
  {
    path: '/merchantview',
    pageTitle: 'merchant view',
    icon: 'merchant',
  },
  {
    path: '/publisherview',
    pageTitle: 'publisher view',
    icon: 'profile',
  },
];

const SideMenuMap: { [key: string]: Array<IsideMenuPageList> } = {
  Publisher: publisherSideMenuPageList,
  Merchant: merchantSideMenuPageList,
  Admin: adminSideMenuPageList,
};

export default SideMenuMap;

import { gql } from '@apollo/client';

export const GET_USER = gql`
  query getUser {
    getUser {
      success
      status
      description
      data {
        size
        users {
          _id
          firstName
          lastName
          userType
          companyId
          companyName
          status
          company {
            accountStatus
            merchantType
          }
          roleId
        }
      }
    }
  }
`;

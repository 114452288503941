import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from '@apollo/client';
import { UserProvider } from 'state/context/userContext';
import { CompanyProvider } from 'state/context/companyContext';
import { AppProvider } from 'state/context/appContext';
import Routes from 'routes';
import GlobalStyles from 'styles/globalStyles';
import { client } from 'utils/apollo';
import './styles/app.css';

ReactDOM.render(
  <>
    <GlobalStyles />
    <ApolloProvider client={client}>
      <UserProvider>
        <CompanyProvider>
          <AppProvider>
            <Routes />
          </AppProvider>
        </CompanyProvider>
      </UserProvider>
    </ApolloProvider>
  </>,
  document.getElementById('root'),
);

import styled from 'styled-components';
import flexGen from 'utils/flexGen';
import { colors, fonts } from 'styles/theme';
import hexToRGB from 'utils/hexToRGB';
import LightenDarkenColor from 'utils/lightenDarkenColor';
import toRem from 'utils/toRem';

interface ISideMenuLink {
  readonly active: boolean;
}

export const StyledSideMenuLinkWrapper = styled.div<ISideMenuLink>`
  ${flexGen()}
  width: 100%;
  border-left: 3px solid
    ${(props) => (props.active ? colors.color1 : colors.color3)};
  background-color: ${(props) => (props.active ? `rgba(${hexToRGB(colors.color2)}, 0.15)` : 'transparent')};

  a {
    ${flexGen('space-between', 'center', 'row')}
    align-content: center;
    padding-top: ${toRem(16)};
    padding-bottom: ${toRem(16)};
    padding-left: ${toRem(12)};
    padding-right: ${toRem(12)};
    text-decoration: none;
    color: ${(props) => (props.active ? colors.color2 : LightenDarkenColor(colors.color2, -90))};
    width: 100%;
    position: relative;
    font-family: ${fonts.font1};
    font-size: ${toRem(14)};

    &:focus {
      outline: none;
    }
  }

  :hover {
    background-color: rgba(${hexToRGB(colors.color2)}, 0.15);
    border-left: 3px solid
      ${(props) => (props.active ? colors.color1 : colors.color4)};

    a {
      color: ${colors.color2};
    }

    svg {
      fill: ${colors.color2};
    }
  }

  svg {
    font-size: 35px;
    fill: ${(props) => (props.active ? colors.color2 : LightenDarkenColor(colors.color2, -90))};
  }
`;

export const StyledSideMenuLinkTitle = styled.div`
  ${flexGen('space-between', 'center', 'row')}
  text-align: center;
  font-size: ${toRem(14)};
`;

import * as React from 'react';

const ActionType: any = {
  GET_TAGS: 'GET_TAGS',
  ADD_TAG: 'ADD_TAG',
  REMOVE_TAG: 'REMOVE_TAG',
};

interface IReducer {
  type: string;
  payload: {
    tags: { id: number; label: string; value: string }[];
  };
}

interface ICompany {
  myCompany: {
    tags: { id: number; label: string; value: string }[];
  };
}

const reducer: React.Reducer<ICompany, IReducer> = (state, action) => {
  switch (action.type) {
    case ActionType.GET_TAGS:
      return { ...state, myCompany: action.payload };
    case ActionType.ADD_TAG:
      return { ...state, myCompany: action.payload };
    case ActionType.REMOVE_TAG:
      return { ...state, myCompany: action.payload };
    default:
      return state;
  }
};

export default reducer;

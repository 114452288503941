import React, {
  useState, useEffect, createContext, useContext,
} from 'react';
import SideMenuMap from 'utils/sideMenuPageList';
import { useUserContext } from './userContext';

const AppContext = createContext<any>([]);

export const AppProvider: React.FC<React.ReactNode> = ({ children }) => {
  const { me } = useUserContext();
  const [hideNavMenu, setHideNavMenu] = useState<boolean>(false);
  const [navMenuList, setNavMenuList] = useState<any[]>([]);

  useEffect(() => {
    if (me) {
      const ctype: string | null = localStorage.getItem('ctype');

      setNavMenuList(SideMenuMap[ctype || me.userType]);
    }
  }, [me]);

  const onHideNavChangeHandler = () => {
    setHideNavMenu((prop) => !prop);
  };

  const onNavMenuListChangeHandler = () => {
    const ctype: string | null = localStorage.getItem('ctype');

    setNavMenuList(SideMenuMap[ctype || me.userType]);
  };

  return (
    <AppContext.Provider
      value={{
        hideNavMenu,
        navMenuList,
        onHideNavChangeHandler,
        onNavMenuListChangeHandler,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);

import styled from 'styled-components';
import toRem from 'utils/toRem';
import LightenDarkenColor from 'utils/lightenDarkenColor';
import { colors } from 'styles/theme';

interface ScrollViewProps {
  borderColor?: string;
  marginRight?: number;
  marginLeft?: number;
  marginTop?: number;
  marginBottom?: number;
  padding?: number;
  paddingRight?: number;
  paddingLeft?: number;
  paddingTop?: number;
  paddingBottom?: number;
  width?: string;
}

const Threshold = styled.div<ScrollViewProps>`
  display: inline;
  border-bottom: ${(props) => `1px solid ${props.borderColor}`};
  margin-top: ${(props) => toRem(props.marginTop!)};
  margin-bottom: ${(props) => toRem(props.marginBottom!)};
  margin-left: ${(props) => toRem(props.marginLeft!)};
  margin-right: ${(props) => toRem(props.marginRight!)};
  padding: ${(props) => toRem(props.padding!)};
  padding-left: ${(props) => toRem(props.paddingLeft!)};
  padding-right: ${(props) => toRem(props.paddingRight!)};
  padding-bottom: ${(props) => toRem(props.paddingBottom!)};
  padding-top: ${(props) => toRem(props.paddingTop!)};

  width: ${(props) => props.width!};
`;

Threshold.defaultProps = {
  borderColor: `${LightenDarkenColor(colors.color2, -60)}`,
  marginRight: 0,
  marginLeft: 0,
  marginTop: 0,
  marginBottom: 0,
  padding: 0,
  paddingRight: 0,
  paddingLeft: 0,
  paddingTop: 0,
  paddingBottom: 0,
  width: '100px',
};

export default Threshold;
